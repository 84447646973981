import { useContext, useEffect, useRef, useState } from 'react'
import '../styles/sidebarMenu.scss'
import { sidebarData } from '../data'
import { Link, useLocation } from 'react-router-dom'
import FavoritesSvg from '../icons/FavoritesSvg'
import colorsContext from '../contexts/ColorsContext'
import BackPageSvg from '../icons/BackPageSvg'
import Avatar from './Avatar/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import SolidLockSvg from '../icons/SolidLockSvg'
import SmallButton from './SmallButton/SmallButton'
import LogoutSvg from '../icons/LogoutSvg'
import BuyButton from './BuyButton/BuyButton'
import ArchiveSvg from '../icons/ArchiveSvg'
import OverlayScrollbar from '../layouts/OverlayScrollbar'
import { setCurrentOpenModal } from '../redux/features/modalSlice'
import useAppUser from '../hooks/useAppUser'
import { Overlay, Popover } from 'react-bootstrap'
import { setSidebarIsOpen } from '../redux/features/sidebarSlice'
import classNames from 'classnames'
import { sidebarTypeEnums } from '../enums/sidebarEnums/sidebarEnums'
import { globalModalEnums } from '../enums/globalEnums/globalEnums'
import { afterLogoutUser } from '../helper'
import useBodyScrollLock from '../hooks/useBodyScrollLock'

const Sidebar = () => {
  const location = useLocation()

  const { colors } = useContext(colorsContext)
  const { appUserData } = useAppUser()
  const { sidebarIsOpen, sidebarType } = useSelector(state => state.sidebar)
  const [isLocked, setIsLocked] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (sidebarIsOpen && (sidebarType === sidebarTypeEnums.classic || window.innerWidth < 992)) {
      setIsLocked(true)
    } else {
      setIsLocked(false)
    }
  }, [sidebarIsOpen])

  useBodyScrollLock(isLocked)

  useEffect(() => {
    // document.body.style.overflow = isActive ? 'hidden' : ''
  }, [sidebarIsOpen])

  const closeSidebar = () => {
    dispatch(setSidebarIsOpen(false))
  }

  const ComingSoonButton = ({ item }) => {
    const target = useRef(null)
    const [showPopover, setShowPopover] = useState(false)

    return (
      <>
        <li className='menu-item'>
          <button ref={target} onClick={() => setShowPopover(!showPopover)} className='menu-item-link'>
            <div className='locked-icon'>
              <SolidLockSvg color={colors['C2']} />
            </div>
            <span className='menu-item-icon'>{item.icon({ color: colors['C2'] })}</span>
            <span className='menu-item-title' style={{ color: colors['C2'] }}>
              {item.title}
            </span>
          </button>
        </li>
        <Overlay
          onHide={() => setShowPopover(false)}
          target={target.current}
          rootClose={true}
          show={showPopover}
          placement='top-start'
        >
          {({ style, ...props }) => (
            <Popover
              className='hint-popover py-1 px-2'
              style={{ ...style, background: colors['C530'], color: '#FCFCFC', borderColor: colors['C530'] }}
              {...props}
            >
              <span>Coming Soon...</span>
            </Popover>
          )}
        </Overlay>
      </>
    )
  }

  const renderList = () => {
    const renderListItems = items => {
      return items.map((item, i) => {
        const isActive = location.pathname === item.url

        if (item.isLocked) {
          return <ComingSoonButton key={i} item={item} />
        } else if (appUserData?.access?.hasPlan || !item.isPremium) {
          return (
            <li key={i} className={`menu-item ${isActive ? 'active' : ''}`}>
              <Link
                to={item.url}
                className='menu-item-link'
                style={{ pointerEvents: isActive ? 'none' : 'initial' }}
                onClick={e => {
                  if (isActive) {
                    e.preventDefault()
                  } else {
                    closeSidebar()
                  }
                }}
              >
                <span className='menu-item-icon'>{item.icon({ color: colors['C2'] })}</span>
                <span className='menu-item-title' style={{ color: colors['C2'] }}>
                  {item.title}
                </span>
                {/*{item.isBeta &&*/}
                {/*  <span className='beta-version' style={{color: colors['C2']}}>*/}
                {/*    Beta version*/}
                {/*  </span>*/}
                {/*}*/}
              </Link>
            </li>
          )
        } else {
          return (
            <li key={i} className={`menu-item ${location.pathname === item.url ? 'active' : ''}`}>
              <button
                className='menu-item-link'
                onClick={() => dispatch(setCurrentOpenModal(globalModalEnums.purchaseRequest))}
              >
                <div className='locked-icon'>
                  <SolidLockSvg color={colors['C2']} />
                </div>
                <span className='menu-item-icon'>{item.icon({ color: colors['C2'] })}</span>
                <span className='menu-item-title' style={{ color: colors['C2'] }}>
                  {item.title}
                </span>
              </button>
            </li>
          )
        }
      })
    }

    return sidebarData.map((pack, packIndex) => {
      return (
        <ul className='menu-list-items' style={{ background: colors[pack.backgroundCode] }} key={packIndex}>
          {renderListItems(pack.items)}
        </ul>
      )
    })
  }

  const onClickClose = e => {
    if (e.target === e.currentTarget && (sidebarType === sidebarTypeEnums.classic || window.innerWidth < 992)) {
      closeSidebar()
    }
  }

  return (
    <div id='sidebar'>
      <div
        className={classNames('sidebar-menu-wrapper', {
          ['active']: sidebarIsOpen,
          ['type--panel']: sidebarType === sidebarTypeEnums.panel
        })}
        onClick={e => onClickClose(e)}
      >
        <div className='sidebar-menu' style={{ background: colors['C73'] }}>
          <OverlayScrollbar>
            <div className='top-icons'>
              <button className='back-icon' onClick={closeSidebar}>
                <BackPageSvg color={colors['C589']} />
              </button>
              {!appUserData?.access?.hasPlan && (
                <>
                  <div className='buy-btn'>
                    <BuyButton />
                  </div>
                  <div />
                </>
              )}
              <div className='d-flex align-items-center justify-content-center gap-3'>
                <Link
                  to='/archive'
                  onClick={() => {
                    closeSidebar()
                  }}
                  className='archive-icon'
                  title='Archive'
                >
                  <ArchiveSvg color={colors['C589']} />
                </Link>
                <Link
                  to='/favorites'
                  onClick={() => {
                    closeSidebar()
                  }}
                  className='heart-icon'
                  title='Favorites'
                >
                  <FavoritesSvg color={colors['C589']} />
                </Link>
              </div>
            </div>
            <div className='user-info'>
              <Avatar isEditable />
              <div className='user_name' style={{ color: colors['C589'] }}>
                {appUserData?.info?.firstName && <p>{appUserData?.info?.firstName}</p>}
                {appUserData?.info?.lastName && <p>{appUserData?.info?.lastName}</p>}
              </div>
            </div>
            <div className='menu-list'>{renderList()}</div>
            <div className='logout-btn'>
              <SmallButton
                onClick={() => {
                  afterLogoutUser()
                }}
                color={colors['C179']}
              >
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='logout-icon' style={{ width: 16, height: 16 }}>
                    <LogoutSvg color={colors['C179']} />
                  </div>
                  Log out
                </div>
              </SmallButton>
            </div>
            <div className='copy-right-text' style={{ color: colors['C589'] }}>
              © 2023 Holland Innovative Education & Technology B.V. All Rights Reserved.
            </div>
          </OverlayScrollbar>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
