import React, { useContext, useEffect, useRef } from 'react'
import NotificationInboxCardItem from '../../molecules/NotificationInboxCardItem'
import { CSSTransition } from 'react-transition-group'
import styles from './inAppNotificationCard.module.scss'
import colorsContext from '../../../contexts/ColorsContext'
import { InAppNotificationData } from 'types/notification/types'
import TranslatedText from '../../../components/TranslatedText/TranslatedText'

type Props = {
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>
  showNotification: boolean
  data: InAppNotificationData
}

const InAppNotificationCard: React.FC<Props> = ({ setShowNotification, showNotification, data }) => {
  const notificationRef = useRef<HTMLDivElement>(null)
  // @ts-ignore
  const { colors } = useContext(colorsContext)

  useEffect(() => {
    const notificationElement = notificationRef.current
    if (!notificationElement) return

    let startY = 0
    let currentY = 0
    let isDragging = false

    // Threshold to dismiss the notification (1/3 of the notification height)
    const threshold = notificationElement.offsetHeight / 3

    const handleStart = (y: number) => {
      startY = y
      isDragging = true
      notificationElement.style.transition = 'none'
    }

    const handleMove = (y: number) => {
      if (!isDragging) return
      currentY = y - startY
      if (currentY < 0) {
        notificationElement.style.transform = `translateY(${currentY}px)`
      }
    }

    const handleEnd = () => {
      if (!isDragging) return
      isDragging = false

      // Dismiss if dragged beyond threshold
      if (Math.abs(currentY) > threshold) {
        notificationElement.style.transition = 'transform 0.3s ease'
        notificationElement.style.transform = 'translateY(calc(-100% - 20px))'
        setTimeout(() => setShowNotification(false), 300) // Wait for animation
      } else {
        // Snap back if threshold not met
        notificationElement.style.transition = 'transform 0.3s ease'
        notificationElement.style.transform = 'translateY(0)'
      }
    }

    const onTouchStart = (e: TouchEvent) => handleStart(e.touches[0].clientY)
    const onTouchMove = (e: TouchEvent) => handleMove(e.touches[0].clientY)
    const onMouseDown = (e: MouseEvent) => handleStart(e.clientY)
    const onMouseMove = (e: MouseEvent) => handleMove(e.clientY)

    // Attach event listeners
    notificationElement.addEventListener('touchstart', onTouchStart)
    notificationElement.addEventListener('touchmove', onTouchMove)
    notificationElement.addEventListener('touchend', handleEnd)
    notificationElement.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', handleEnd)

    // Cleanup listeners
    return () => {
      notificationElement.removeEventListener('touchstart', onTouchStart)
      notificationElement.removeEventListener('touchmove', onTouchMove)
      notificationElement.removeEventListener('touchend', handleEnd)
      notificationElement.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', handleEnd)
    }
  }, [setShowNotification])

  // Remove notification with animation
  const handleRemoveNotification = () => {
    const notificationElement = notificationRef.current
    if (notificationElement) {
      notificationElement.style.transition = 'transform 0.3s ease'
      notificationElement.style.transform = 'translateY(calc(-100% - 20px))'
      setTimeout(() => setShowNotification(false), 300)
    }
  }

  const CSSTransitionComponent = CSSTransition as React.ComponentType<any>

  return (
    <CSSTransitionComponent
      in={showNotification}
      timeout={300}
      classNames={{
        enter: styles.notificationEnter,
        enterActive: styles.notificationEnterActive
      }}
      unmountOnExit
    >
      <div className={styles.notificationWrapper}>
        <div ref={notificationRef} className={styles.notificationContainer}>
          {/* @ts-ignore */}
          <NotificationInboxCardItem
            cardBackground={colors['C183']}
            textColor={colors['C2']}
            badgeItem={data.badgeType}
            contentText={data.message}
            link={data.link}
            time={<TranslatedText translationKey={'just.now'} />}
            onRemove={handleRemoveNotification}
            transform=''
            isDragging={false}
            handleCancelClick={handleRemoveNotification}
            isToast={true}
          />
        </div>
      </div>
    </CSSTransitionComponent>
  )
}

export default InAppNotificationCard
