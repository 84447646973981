import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import appUserUseCase from '../../useCase/appUser/appUserUseCase'
import deleteAppUserUseCase from '../../useCase/appUser/deleteAppUserUseCase'
import userInfoMapper from '../../services/mapper/userInfo/userInfoMapper'
import userSettingMapper from '../../services/mapper/userSetting/userSettingMapper'
import updateNotificationReceiverUseCase from '../../useCase/updateNotificationReceiverUseCase/updateNotificationReceiverUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

interface InitialState {
  loading: boolean,
  appUserData: any | null,
  error: string
}

const initialState: InitialState = {
  loading: false,
  appUserData: null,
  error: ''
}

export const getAppUser = baseAsyncThunk('appUser/getAppUser', () => appUserUseCase())

export const deleteAppUserAccount = createAsyncThunk('appUser/deleteAppUserAccount', () => deleteAppUserUseCase())

export const updateNotificationReceiver = createAsyncThunk(
  'appUser/updateNotificationReceiver',
  ({ registrationToken, deviceId }: { registrationToken: string, deviceId: string }) => {
    return updateNotificationReceiverUseCase({ registrationToken, deviceId })
  }
)

const appUserSlice = createSlice({
  name: 'appUser',
  initialState,
  reducers: {
    setAppUser: (state, action) => {
      if (action.payload?.info && action.payload?.setting && action.payload?.access && action.payload?.action) {
        state.appUserData = {
          info: userInfoMapper(action.payload?.info),
          setting: userSettingMapper(action.payload?.setting),
          access: action.payload?.access,
          action: action.payload?.action,
          pwaVersion: action.payload?.pwaVersion
        }
      }
    },
    setAiTokens: (state, action) => {
      if (state.appUserData?.setting?.aiTokens) {
        state.appUserData.setting.aiTokens = action.payload
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(getAppUser.pending, state => {
      state.loading = true
    })
    builder.addCase(getAppUser.fulfilled, (state, action) => {
      state.loading = false
      state.appUserData = action.payload
    })
    builder.addCase(getAppUser.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? ''
    })
    builder.addCase(deleteAppUserAccount.pending, state => {
      state.loading = true
    })
    builder.addCase(deleteAppUserAccount.fulfilled, () => {
      window.location.href = '/'
    })
    builder.addCase(deleteAppUserAccount.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message ?? ''
    })
  }
})

export const { setAppUser, setAiTokens } = appUserSlice.actions

export default appUserSlice.reducer
