export enum NotificationClassType {
  new = 'NEW',
  repeat = 'REPEAT',
  review = 'REVIEW',
  memoryBox = 'MEMORY_BOX',
  announcements = 'ANNOUNCEMENTS',
  accountRenewal = 'ACCOUNT_RENEWAL',
  all = 'ALL'
}

export enum NotificationBadgeType {
  new = 'new',
  repeat = 'repeat',
  review = 'review',
  cards = 'cards',
  notice = 'notice'
}

export interface NotificationInboxCountServiceModel {
  NEW: string | number
  MEMORY_BOX: string | number
  ANNOUNCEMENTS: string | number
  REPEAT: string | number
  REVIEW: string | number
  ACCOUNT_RENEWAL: string | number
}

export interface NotificationInboxServiceModel {
  id: string
  unitId: number
  notificationTime: string // ISO string for the notification time
  userStudyDay: number
  title: string
  message: string
  notificationCategory: NotificationClassType
  notificationClass: NotificationClassType
  memoryBoxCategoryId?: string
  memoryBoxCategoryName?: string
  repeatNumber: number
  seenStatus: boolean
}