import componentStyle from './ButtonTemplate.module.scss'
import React, { useContext, useEffect, useRef } from 'react'
import colorsContext from '../../../../contexts/ColorsContext'
import PageLayer from '../../../../components/PageLayer/PageLayer'
import MoreStructuresPage from './MoreStructuresPage'
import { useLocation, useSearchParams } from 'react-router-dom'
import structuresContext from '../../../../contexts/StructuresContext'
import { robotTeacherScrollIntoViewByElement } from '../../../../operations/robotTeacherScrollIntoViewByElement'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import useQueryParams from '../../../../hooks/useQueryParams'

const ButtonTemplate = ({ template }) => {
  const { colors } = useContext(colorsContext)

  const [searchParams] = useSearchParams()
  const { addQueryParams } = useQueryParams()
  const templateParam = searchParams.get('template')
  const location = useLocation()

  const elRef = useRef(null)
  const { scrolling } = useSelector(state => state.roboTeacher)
  const { isRoboTeacher, roboTeacherData } = useContext(structuresContext)
  const isHidden = isRoboTeacher ?? false ? (roboTeacherData.currentTime ?? 0) < (template?.hiddenTime ?? 0) : false

  useEffect(() => {
    if (template?.hiddenTime > 0 && !isHidden && isRoboTeacher && !scrolling) {
      robotTeacherScrollIntoViewByElement(elRef?.current)
    }
  }, [isHidden])

  return (
    <>
      <div className='container container-1'>
        <button
          ref={elRef}
          data-rt-id={template?.id}
          className={classNames(componentStyle.button, { ['hide-element']: isHidden })}
          onClick={() => {
            addQueryParams({ template: template.id }, { state: location.state })
          }}
          style={{ background: colors['C200'], color: colors['C223'] }}
        >
          {template.config.caption}
        </button>
      </div>
      {templateParam && templateParam === template.id && (
        <PageLayer background={colors['C183']}>
          <MoreStructuresPage template={template} />
        </PageLayer>
      )}
    </>
  )
}

export default ButtonTemplate
