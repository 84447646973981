import componentStyle from './AIChat.module.scss'
import FramerMotion from '../../layouts/FramerMotion'
import React, { useContext, useEffect, useRef, useState } from 'react'
import colorsContext from '../../contexts/ColorsContext'
import Header from '../../components/Header/Header'
import AICreditBadge from '../../components/AICreditBadge/AICreditBadge'
import classNames from 'classnames'
import ChatBox from '../../components/ChatBox/ChatBox'
import HtmlTextWrapper from '../../components/HtmlTextWrapper/HtmlTextWrapper'
import SendButton from '../../components/SendButton'
import ThumbUpSvg from '../../icons/ThumbUpSvg'
import ThumbDownSvg from '../../icons/ThumbDownSvg'
import ProvideAIChatFeedbackModal from '../../components/ProvideAIChatFeedbackModal/ProvideAIChatFeedbackModal'
import { useDispatch, useSelector } from 'react-redux'
import SelectSubjectModal from './Components/SelectSubjectModal'
import BuyTokenButton from '../../components/BuyTokenButton/BuyTokenButton'
import { useParams } from 'react-router-dom'
import {
  getAiChatRoleDescriptions,
  pushToDialogs,
  resetAiChatStates,
  sendAiChatPrompt,
  setIsFinishedAIChat,
  setSelectedSubject,
  updateLastRobotDialogToGenerating
} from '../../redux/features/aiChatSlice'
import ThumbDownFillSvg from '../../icons/ThumbDownFillSvg'
import ThumbUpFillSvg from '../../icons/ThumbUpFillSvg'
import makeAvatarUrl from '../../operations/makeAvatarUrl'
import useAppUser from '../../hooks/useAppUser'
import ResetSvg from '../../icons/ResetSvg'
import { setAiTokens } from '../../redux/features/appUserSlice'
import HelpButton from '../../components/HelpButton/HelpButton'
import { isEmpty } from 'lodash'
import SpeechCircleButton from '../../components/SpeechCircleButton/SpeechCircleButton'
import useWebSpeechApi from '../../hooks/speechToText/useWebSpeechApi'
// import Markdown from 'react-markdown'
// import remarkGfm from 'remark-gfm'
import Markdown from 'markdown-to-jsx'
import { pageGuidEnums } from '../../enums/pageGuideEnums/pageGuideEnums'

const AIChat = ({ promptType }) => {
  const { colors } = useContext(colorsContext)
  const [showSelectSubjectModal, setShowSelectSubjectModal] = useState(false)
  const [feedback, setFeedback] = useState({
    isOpen: false,
    dialogId: null,
    isLiked: false
  })
  const inputRef = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const { outOfCredit, dialogs, selectedSubject, promptId, loading, isFinished } = useSelector(state => state.aiChat)
  const dispatch = useDispatch()
  const { appUserData } = useAppUser()
  const { unitId } = useParams()

  const {
    webSpeechRecording,
    webSpeechResult,
    webSpeechRecordFinished,
    webSpeechNotSupported,
    webSpeechStartRecording,
    webSpeechFinishRecording,
    webSpeechResetRecording
  } = useWebSpeechApi()

  useEffect(() => {
    setInputValue(webSpeechResult ?? '')
    if (inputRef?.current) {
      inputRef.current.scrollLeft = inputRef.current.scrollWidth
    }
  }, [webSpeechResult])

  useEffect(() => {
    if (webSpeechResult) {
      onSubmitChatInput()
    }
  }, [webSpeechRecordFinished])

  useEffect(() => {
    if (!isEmpty(unitId)) {
      dispatch(getAiChatRoleDescriptions({ unitId, promptType })).then(res => {
        if (res.payload?.length === 1) {
          dispatch(setSelectedSubject(res.payload[0]))
          dispatch(
            pushToDialogs({
              content: res.payload[0].greetingText,
              isRobot: true,
              isTyping: false
            })
          )
        } else if (res.payload?.length > 1) {
          setShowSelectSubjectModal(true)
        }
      })
    } else if (promptType === 'AI_HOMEPAGE') {
      dispatch(getAiChatRoleDescriptions({ unitId: 1001, promptType })).then(res => {
        if (res.payload?.length === 1) {
          dispatch(setSelectedSubject(res.payload[0]))
          sendPrompt('')
        }
      })
    }

    return () => {
      dispatch(resetAiChatStates())
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight })
    }, 10)
  }, [dialogs])

  const onClickMicrophone = () => {
    if (!loading) {
      if (webSpeechRecording) {
        webSpeechFinishRecording()
      } else {
        webSpeechResetRecording()
        webSpeechStartRecording()
      }
    }
  }

  const sendPrompt = content => {
    dispatch(sendAiChatPrompt({ content, promptType })).then(res => {
      if (res.payload) {
        dispatch(setAiTokens(res.payload.totalTokens))
      }
    })
  }

  const onSubmitChatInput = e => {
    e?.preventDefault()
    if (!outOfCredit && inputValue.trim() !== '') {
      dispatch(pushToDialogs({ content: inputValue, isRobot: false, isTyping: false }))
      setInputValue('')
      setTimeout(() => {
        dispatch(pushToDialogs({ content: '', isRobot: true, isTyping: true, checkLastDialog: true }))
      }, 100)
      sendPrompt(inputValue)
    }
  }

  const onClickFeedback = (dialog, isLiked) => {
    setFeedback({
      isOpen: true,
      dialogId: dialog.id,
      isLiked
    })
  }

  const onClickFinishChatButton = () => {
    if (!outOfCredit && !loading) {
      dispatch(pushToDialogs({ content: '', isRobot: true, isTyping: true, checkLastDialog: false }))
      dispatch(setIsFinishedAIChat(true))
      setTimeout(() => {
        sendPrompt('#FINISH#')
      }, 10)
    }
  }

  const onClickRegenerateButton = () => {
    if (!outOfCredit && !loading) {
      const userDialogs = dialogs?.filter(it => !it.isRobot)
      const lastUserDialog = userDialogs?.[userDialogs?.length - 1]
      if (lastUserDialog?.content?.sentence) {
        dispatch(updateLastRobotDialogToGenerating())
        setTimeout(() => {
          sendPrompt(isFinished ? '#FINISH#' : lastUserDialog.content.sentence)
        }, 10)
      }
    }
  }

  const renderChatAvatar = ({ isRobot }) => {
    return (
      <div className={componentStyle.profile}>
        <div className={componentStyle.avatar}>
          <img
            src={
              isRobot
                ? `${window.location.origin}/assets/images/mascot-head.png`
                : makeAvatarUrl(appUserData?.setting?.avatar)
            }
            alt=''
          />
        </div>
        <span className={classNames(componentStyle.username, 'res:fs-xs')} style={{ color: colors['C2'] }}>
          {isRobot ? 'AI' : 'Me'}
        </span>
      </div>
    )
  }

  const renderDialogs = () => {
    return dialogs.map((dialog, i) => {
      return (
        <div
          className={classNames(componentStyle.chatBoxWrapper, {
            [`${componentStyle.left} ${componentStyle.chatBoxMotion}`]: dialog.isRobot,
            [`${componentStyle.right} motion-opacity-1 motion-scale-1`]: !dialog.isRobot
          })}
          key={i}
        >
          {renderChatAvatar({ isRobot: dialog.isRobot })}
          <ChatBox
            contentColor={dialog.isRobot ? colors['C23'] : colors['C209']}
            isTyping={dialog.isTyping}
            borderColor={dialog.isError ? colors['C29'] : null}
            bgColor={dialog.isError ? '#ef44441a' : dialog.isRobot ? colors['C234'] : colors['C235']}
            toLeft={!dialog.isRobot}
          >
            <div style={{ color: dialog.isError ? colors['C160'] : dialog.isRobot ? colors['C23'] : colors['C209'] }}>
              {dialog.isRobot ? (
                <Markdown className={componentStyle.chatBoxContent}>{dialog.content.sentence}</Markdown>
              ) : (
                <div>{dialog.content.sentence}</div>
              )}
            </div>
            {dialog.isRobot && dialog.showFeedbackButtons && (
              <div className={componentStyle.feedbackIcons}>
                <button disabled={dialog.feedback?.liked === true} onClick={() => onClickFeedback(dialog, true)}>
                  {dialog.feedback && dialog.feedback?.liked ? (
                    <ThumbUpFillSvg color={colors['C23']} />
                  ) : (
                    <ThumbUpSvg color={colors['C23']} />
                  )}
                </button>
                <button disabled={dialog.feedback?.liked === false} onClick={() => onClickFeedback(dialog, false)}>
                  {dialog.feedback && !dialog.feedback?.liked ? (
                    <ThumbDownFillSvg color={colors['C23']} />
                  ) : (
                    <ThumbDownSvg color={colors['C23']} />
                  )}
                </button>
              </div>
            )}
          </ChatBox>
        </div>
      )
    })
  }

  const lastDialog = dialogs?.[dialogs?.length - 1]

  return (
    <>
      <FramerMotion className='minh-100vh' style={{ background: colors['C183'] }}>
        <Header
          title={promptType === 'AI_ROBOT_TEACHER' ? 'AI Teacher' : 'AI Chat'}
          withBackButton
          rightItems={<AICreditBadge showTitle titleColor={colors['C179']} />}
        />
        <div className='container-fluid mt-3'>
          <div className='d-flex justify-content-end me-3 me-md-5'>
            <HelpButton color={colors['C2']} pageType={pageGuidEnums.CHAT_WITH_AI} />
          </div>
        </div>
        <div className='container py-4'>
          {selectedSubject && (
            <div className={componentStyle.chatContainer}>
              {renderDialogs()}
              {outOfCredit && (
                <div
                  className={classNames(
                    componentStyle.chatBoxWrapper,
                    componentStyle.left,
                    componentStyle.chatBoxMotion
                  )}
                >
                  {renderChatAvatar({ isRobot: true })}
                  <ChatBox bgColor={colors['C234']}>
                    <HtmlTextWrapper
                      className='res:fs-md'
                      textColor={colors['C23']}
                      data={{
                        sentence:
                          'You are out of credit. If you wish to continue, click on "Purchase" button and refill your credit.'
                      }}
                    />
                    <div className='d-flex justify-content-center mt-3'>
                      <BuyTokenButton />
                    </div>
                  </ChatBox>
                </div>
              )}
            </div>
          )}
          {lastDialog?.isError ? (
            <div className={componentStyle.chatForm}>
              <p className='fs-xs text-center mb-2' style={{ color: colors['C2'] }}>
                There was an error generating a response
              </p>
              <button
                disabled={loading}
                className='mx-auto rounded btn d-flex align-items-center justify-content-center gap-2 fs-sm px-3 py-2'
                style={{ color: colors['C179'], background: colors['C11'], opacity: loading ? 0.5 : 1 }}
                onClick={onClickRegenerateButton}
              >
                <span className={classNames(componentStyle.regenerateButtonIcon)}>
                  <ResetSvg color={colors['C2']} />
                </span>
                Regenerate
              </button>
            </div>
          ) : (
            !isFinished && (
              <form
                onSubmit={e => onSubmitChatInput(e)}
                className={componentStyle.chatForm}
                style={{ background: colors['C183'], opacity: !outOfCredit && !loading ? 1 : 0.5 }}
              >
                <div className='container'>
                  {dialogs.length >= 3 && (
                    <ul className={componentStyle.suggestions}>
                      <li>
                        <button
                          disabled={outOfCredit || loading}
                          onClick={onClickFinishChatButton}
                          type='button'
                          style={{ borderColor: colors['C2'], color: colors['C2'] }}
                        >
                          Finish
                        </button>
                      </li>
                    </ul>
                  )}
                  <div className={componentStyle.chatInputContainer}>
                    <div
                      style={{ border: `1px solid ${colors['C2']}` }}
                      className={classNames(componentStyle.inputWrapper, { [componentStyle.disabled]: outOfCredit })}
                    >
                      <input
                        ref={inputRef}
                        disabled={outOfCredit || loading}
                        type='text'
                        value={inputValue}
                        onChange={e => {
                          if (!outOfCredit && !loading) {
                            setInputValue(e.target.value)
                          }
                        }}
                        style={{ background: colors['C160'], color: colors['C233'] }}
                      />
                      {!webSpeechNotSupported && (
                        <div className={componentStyle.microphoneContainer}>
                          <SpeechCircleButton
                            className={componentStyle.microphoneButton}
                            iconColor={colors['C233']}
                            borderColor='transparent'
                            isSmall
                            recording={webSpeechRecording}
                            onClick={onClickMicrophone}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      className={classNames(componentStyle.submitButtonWrapper, {
                        [componentStyle.disabled]: outOfCredit
                      })}
                    >
                      <SendButton disabled={outOfCredit || loading} type='submit' />
                    </div>
                  </div>
                </div>
              </form>
            )
          )}
        </div>
      </FramerMotion>
      {showSelectSubjectModal && <SelectSubjectModal closeModal={() => setShowSelectSubjectModal(false)} />}
      {feedback.isOpen && (
        <ProvideAIChatFeedbackModal
          promptId={promptId}
          isLiked={feedback.isLiked}
          dialogId={feedback.dialogId}
          closeModal={() =>
            setFeedback({
              isOpen: false,
              dialogId: null,
              isLiked: false
            })
          }
        />
      )}
    </>
  )
}

export default AIChat
