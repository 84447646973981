import { Link } from 'react-router-dom'
import SecondaryButton from '../../atomicComponents/atoms/SecondaryButton/SecondaryButton'
import Header from '../../components/Header/Header'
import React, { useContext } from 'react'
import FramerMotion from '../../layouts/FramerMotion'
import colorsContext from '../../contexts/ColorsContext'
import componentStyle from './notFound.module.scss'


const NotFound = () => {
	//@ts-ignore
	const { colors } = useContext(colorsContext)

	return (
		<div className={componentStyle.notFoundPage}>
			<div className={componentStyle.container}>
				<div>
					<h2>404 - Page Not Found</h2>
					<p>Sorry, the page you are looking for does not exist.</p>
					<div className={componentStyle.vellsoTV}>
						<img src={`${window.location.origin}/assets/gif/notFoundPageGif.gif`} />
					</div>
				</div>
				<SecondaryButton linkTo={'/'}>Back To Home</SecondaryButton>
			</div>
		</div>
	)
}

export default NotFound