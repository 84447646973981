import { NotificationBadgeType, NotificationClassType, NotificationInboxServiceModel } from "services/api/notification/types"
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

export const getNotificationBadgeTypeByClass = (classType: NotificationClassType) => {
  switch (classType) {
    case NotificationClassType.new:
      return NotificationBadgeType.new
    case NotificationClassType.repeat:
      return NotificationBadgeType.repeat
    case NotificationClassType.review:
      return NotificationBadgeType.review
    case NotificationClassType.memoryBox:
      return NotificationBadgeType.cards
    case NotificationClassType.announcements:
    case NotificationClassType.accountRenewal:
    default:
      return NotificationBadgeType.notice
  }
}

// TODO: Use calender plugin instead of conditions
const getDateTime = (t: string) => {
  dayjs.extend(isToday)
  dayjs.extend(isYesterday)

  let date = ''
  let time = ''
  let dayjsInstance = dayjs(t)

  if (dayjsInstance.isToday()) {
    date = 'Today'
  } else if (dayjsInstance.isYesterday()) {
    date = 'Yesterday'
  } else {
    date = dayjsInstance.format('YYYY/MM/DD')
  }

  time = dayjsInstance.format('h:mm A')

  return `${date}, ${time}`
}

const createNotificationLink = (notif: NotificationInboxServiceModel) => {
  switch (notif.notificationClass) {
    case NotificationClassType.new:
    case NotificationClassType.repeat:
    case NotificationClassType.review:
      return `/sections/auto-redirect/${notif.unitId}`
    case NotificationClassType.accountRenewal:
      return '/payment'
    case NotificationClassType.memoryBox:
      return `/memory-box/category/${notif.memoryBoxCategoryId}/box?categoryTitle=${notif.memoryBoxCategoryName}`
    case NotificationClassType.announcements:
    case NotificationClassType.all:
    default:
      return ''
  }
}

const notificationInboxMapper = (data: NotificationInboxServiceModel[]) => {
  return data.map(it => {
    return {
      id: it.id,
      unitId: it.unitId,
      notificationTime: getDateTime(it.notificationTime),
      userStudyDay: it.userStudyDay,
      title: it.title,
      message: it.message,
      notificationCategory: it.notificationCategory,
      notificationClass: it.notificationClass,
      badgeType: getNotificationBadgeTypeByClass(it.notificationClass),
      memoryBoxCategoryId: it.memoryBoxCategoryId,
      memoryBoxCategoryName: it.memoryBoxCategoryName,
      repeatNumber: it.repeatNumber,
      seenStatus: it.seenStatus,
      link: createNotificationLink(it)
    }
  })
}

export type NotificationInboxState = ReturnType<typeof notificationInboxMapper>

export default notificationInboxMapper