import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteNotificationsByClassUseCase, deleteNotificationsByIdUseCase, getNotificationInboxCountUseCase, getNotificationInboxUseCase, seenNotificationByIdUseCase } from 'useCase/messages'
import { NotificationClassType } from 'services/api/notification/types'
import { NotificationInboxCountState } from 'services/mapper/messages/notificationInboxCountMapper'
import { NotificationInboxState } from 'services/mapper/messages/notificationInboxMapper'

interface GetNotificationsInboxArgs {
  classType: NotificationClassType
}

interface SeenNotificationByIdArgs {
  id: string
}

interface DeleteNotificationsByClassArgs {
  classTypes: NotificationClassType[]
}
interface DeleteNotificationsByIdArgs {
  ids: string[]
}

interface InitalState {
  loading: boolean
  pendingDelete: boolean
  notificationsCount?: NotificationInboxCountState | null
  notifications?: NotificationInboxState
  error?: string | null
}

const initialState: InitalState = {
  loading: false,
  pendingDelete: false,
  notificationsCount: null,
  notifications: [],
  error: ''
}

export const getNotificationsInboxCount = createAsyncThunk(
  'messagesCategory/getNotificationsInboxCount',
  () => getNotificationInboxCountUseCase()
)

export const getNotificationsInbox = createAsyncThunk(
  'messagesCategory/getNotificationsInbox',
  async ({ classType }: GetNotificationsInboxArgs) => await getNotificationInboxUseCase({ classType })
)

export const seenNotificationById = createAsyncThunk(
  'messagesCategory/seenNotificationById',
  ({ id }: SeenNotificationByIdArgs) => seenNotificationByIdUseCase({ id })
)

export const deleteNotificationsByClass = createAsyncThunk(
  'messagesCategory/deleteNotificationsByClass',
  ({ classTypes }: DeleteNotificationsByClassArgs) => deleteNotificationsByClassUseCase({ classTypes })
)

export const deleteNotificationsById = createAsyncThunk(
  'messagesCategory/deleteNotificationsById',
  ({ ids }: DeleteNotificationsByIdArgs) => deleteNotificationsByIdUseCase({ ids })
)

const messagesCategorySlice = createSlice({
  name: 'messagesCategory',
  initialState,
  reducers: {
    removeNotificationFromState: (state, action) => {
      state.notifications = state.notifications?.filter(it => it.id !== action.payload);
    },
    clearNotificationsInboxState: (state) => {
      state.notifications = []
    }
  },
  extraReducers: builder => {
    // get summery
    builder.addCase(getNotificationsInboxCount.pending, state => {
      state.loading = true
    })
    builder.addCase(getNotificationsInboxCount.fulfilled, (state, action) => {
      state.loading = false
      state.notificationsCount = action.payload
    })
    builder.addCase(getNotificationsInboxCount.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // get Category Notifications
    builder.addCase(getNotificationsInbox.pending, state => {
      state.loading = true
    })
    builder.addCase(getNotificationsInbox.fulfilled, (state, action) => {
      state.loading = false
      state.notifications = action.payload
    })
    builder.addCase(getNotificationsInbox.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    builder.addCase(deleteNotificationsByClass.pending, state => {
      state.pendingDelete = true
    })
    builder.addCase(deleteNotificationsByClass.fulfilled, (state, action) => {
      state.pendingDelete = false
    })
    builder.addCase(deleteNotificationsByClass.rejected, (state, action) => {
      state.pendingDelete = false
      state.error = action.error.message
    })


    builder.addCase(deleteNotificationsById.pending, state => {
      state.pendingDelete = true
    })
    builder.addCase(deleteNotificationsById.fulfilled, (state, action) => {
      state.pendingDelete = false
    })
    builder.addCase(deleteNotificationsById.rejected, (state, action) => {
      state.pendingDelete = false
      state.error = action.error.message
    })
  }
})

export const { removeNotificationFromState, clearNotificationsInboxState } = messagesCategorySlice.actions

export default messagesCategorySlice.reducer
