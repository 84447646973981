import { useEffect } from 'react'

const useBodyScrollLock = isLocked => {
  useEffect(() => {
    if (isLocked) {
      // Lock the scroll
      document.body.style.overflow = 'hidden'
    } else {
      // Unlock the scroll
      document.body.style.overflow = ''
    }

    // Clean up on unmount
    return () => {
      document.body.style.overflow = ''
    }
  }, [isLocked])
}

export default useBodyScrollLock
