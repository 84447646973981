import InAppNotificationCard from 'atomicComponents/organisms/InAppNotificationCard'
import { useEffect, useState } from 'react'
import { InAppNotificationData } from 'types/notification/types'

const InAppNotifications = () => {
  const [notification, setNotification] = useState<InAppNotificationData | null>(null)
  const [showNotification, setShowNotification] = useState(false)

  const onFcmMessage = (e: any) => {
    setShowNotification(false)
    setNotification(e.detail)
    setTimeout(() => {
      setShowNotification(true)
    }, 300)
  }

  useEffect(() => {
    document.addEventListener('fcmMessage', onFcmMessage)

    return () => {
      document.removeEventListener('fcmMessage', onFcmMessage)
    }
  }, [])

  return (
    <>
      {notification !== null && (
        <InAppNotificationCard
          setShowNotification={setShowNotification}
          showNotification={showNotification}
          data={notification}
        />
      )}
    </>
  )
}

export default InAppNotifications
