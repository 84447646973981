import { useLiveQuery } from 'dexie-react-hooks'
import { translationDb } from '../../db/translationDb'
import { useEffect, useState } from 'react'
import useTranslations from '../../translations/useTranslations'
import { useSelector } from 'react-redux'
import { translationsLanguagesData } from '../../enums/languageEnums/languageEnums'

const TranslatedText = props => {
  const { translationKey = '', middleware } = props
  const [language] = useState(null)
  const [translationUpdate, setTranslationUpdate] = useState(0)
  const { addTranslation } = useTranslations()
  const { selectedTranslationLanguage } = useSelector(state => state.translation)

  const [translation, loaded] = useLiveQuery(
    () => {
      return translationDb
        .table(selectedTranslationLanguage.dbTable)
        .get(translationKey)
        ?.then(item => {
          return [item, true]
        })
    },
    [language, translationKey, translationUpdate, selectedTranslationLanguage],
    [null, false]
  )

  useEffect(() => {
    const usedTranslationDbVersions = JSON.parse(localStorage.getItem('translation_db_version') ?? null)
    const currentLanguageUsedTranslationVersion = usedTranslationDbVersions?.[selectedTranslationLanguage.key] || '0'

    const latestTranslationDbVersion = selectedTranslationLanguage.dbVersionCode || '0'
    if (loaded && (!translation || latestTranslationDbVersion !== currentLanguageUsedTranslationVersion)) {
      addTranslation(selectedTranslationLanguage).then(() => {
        let newVersionsData = {}
        Object.keys(translationsLanguagesData).forEach(function (key, index) {
          if (key === selectedTranslationLanguage.key) {
            newVersionsData[key] = latestTranslationDbVersion
          } else if (usedTranslationDbVersions?.[key]) {
            newVersionsData[key] = usedTranslationDbVersions?.[key]
          } else {
            newVersionsData[key] = '0'
          }
        })

        localStorage.setItem('translation_db_version', JSON.stringify(newVersionsData))
        setTranslationUpdate(old => old + 1)
      })
    }
  }, [translation, language, loaded, translationKey])

  if (loaded && translation) {
    if (typeof middleware === 'function') {
      return middleware(translation?.value)
    } else {
      return translation?.value
    }
  } else {
    return ''
  }
}

export default TranslatedText
