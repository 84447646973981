import ColorsContext from 'contexts/ColorsContext'
import { useContext } from 'react'
import componentStyles from './RetryServiceButton.module.scss'
import SecondaryButton from 'atomicComponents/atoms/SecondaryButton/SecondaryButton'
import TranslatedText from '../../../components/TranslatedText/TranslatedText'
import useTranslatedText from '../../../hooks/useTranslatedText'

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const RetryServiceButton = (props: Props) => {
  const { dir } = useTranslatedText()

  return (
    <div
      className={`${componentStyles.container} tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-[calc(100dvh-130px)]`}
    >
      <p dir={dir} className='tw-text-xl tw-font-bold tw-text-center tw-mb-4'>
        {/* @ts-ignore */}
        <TranslatedText translationKey={'retry.description'} />
      </p>
      {/* <ResetButton color={colors['C2']} text='Retry' onClick={props.onClick} /> */}
      {/* @ts-ignore */}
      <SecondaryButton onClick={props.onClick}>
        <TranslatedText translationKey={'retry'} />
      </SecondaryButton>
    </div>
  )
}

export default RetryServiceButton
