  import moment from 'moment'

  const settingNotificationMapper = data => {
    return {
      fromTime: moment(data?.fromTime).format('HH:mm'),
      toTime: moment(data?.toTime).format('HH:mm'),
      allowAllTheTime: data?.allowAllTheTime ?? true,
      allowRepeatUnitNotification: data?.allowRepeatUnitNotification ?? true,
      allowMemoryBoxNotification: data?.allowMemoryBoxNotification ?? true,
      allowReviewUnitNotification: data?.allowReviewUnitNotification ?? true,
      allowNewUnitNotification: data?.allowNewUnitNotification ?? true
      // appBadges: data?.appBadges,
      // floating: data?.floating,
      // lockScreen: data?.lockScreen,
      // allowSound: data?.allowSound ?? true,
      // allowVibration: data?.allowVibration ?? false,
      // incompleteUnit: data?.incompleteUnit ?? false,
      // newUnitOverdue: data?.newUnitOverdue ?? false,
      // reviewCountdown: data?.reviewCountdown ?? false,
      // reviewOverdue: data?.reviewOverdue ?? false,
      // repetitionIncompleteExercise: data?.repetitionIncompleteExercise ?? true,
      // exerciseAvailable: data?.exerciseAvailable ?? true,
      // repetitionOverdue: data?.repetitionOverdue ?? false,
      // cardAvailable: data?.cardAvailable ?? true,
      // memoryBoxOverdue: data?.memoryBoxOverdue ?? false,
      // acceptedRequests: data?.acceptedRequests ?? false,
      // incomingRequests: data?.incomingRequests ?? true,
      // vocabMaster: data?.vocabMaster ?? false,
      // accountRenewal: data?.accountRenewal ?? true,
      // festival: data?.festival ?? false
    }
  }
  export default settingNotificationMapper
