import classNames from 'classnames'
import componentStyle from '../../organisms/NotificationInboxCard/NotificationCard.module.scss'
import TodayStudyTypeBadge from '../../atoms/TodayStudyTypeBadge/TodayStudyTypeBadge'
import CancelSvg from '../../../icons/CancelSvg'
import { NotificationBadgeType } from '../../../services/api/notification/types'
import useAppUser from '../../../hooks/useAppUser'
import useTranslatedText from '../../../hooks/useTranslatedText'
import { useContext } from 'react'
import colorsContext from '../../../contexts/ColorsContext'
import { languagesEnums } from '../../../enums/languageEnums/languageEnums'
import { useNavigate } from 'react-router-dom'

type Props = {
  badgeItem: NotificationBadgeType
  contentText: string
  time: any
  onRemove: () => void
  link?: string
  transform: string
  isDragging: any
  handleCancelClick: any
  isToast?: boolean
  cardBackground: string
  textColor: string
}

const NotificationLeftSideColors: Record<string, string> = {
  repeat: '#E7DADE',
  review: '#D8E7E6',
  new: '#DAEACB',
  cards: '#BEC6D5',
  notice: '#F1E2D5'
}

const NotificationInboxCardItem = ({
  link,
  isDragging,
  badgeItem,
  handleCancelClick,
  contentText,
  time,
  transform,
  isToast,
  cardBackground,
  textColor
}: Props) => {
  const { appUserData } = useAppUser()
  const { dir } = useTranslatedText()
  // @ts-ignore
  const { colors } = useContext(colorsContext)
  const navigate = useNavigate()

  const rtlStyles =
    appUserData?.setting?.nativeLanguage === languagesEnums.PERSIAN_LANGUAGE ||
    appUserData?.setting?.nativeLanguage === languagesEnums.ARABIC_LANGUAGE
  const backgroundColor = NotificationLeftSideColors[badgeItem]

  const onClickCard = () => {
    if (link) navigate(link)
    if (isToast) handleCancelClick()
  }

  return (
    <>
      <div
        className={classNames(componentStyle.card, { [componentStyle.dragging]: isDragging })}
        style={{
          transform: transform,
          transition: isDragging ? 'none' : 'transform 500ms ease',
          background: cardBackground ?? colors['C609']
        }}
      >
        <button
          className={componentStyle.cross}
          style={rtlStyles ? { left: '14px' } : { right: '14px' }}
          onClick={handleCancelClick}
        >
          <CancelSvg color={textColor ?? colors['C2']} />
        </button>
        <div className={componentStyle.cardLink} onClick={onClickCard} draggable={false}>
          <div>
            <div className={componentStyle.badgeContainer} style={rtlStyles ? { right: '25px' } : { left: '25px' }}>
              <TodayStudyTypeBadge type={badgeItem} size={'medium'} />
            </div>
            <div
              className={`tw-absolute tw-h-full tw-w-4 ${rtlStyles ? 'tw-right-0' : 'tw-left-0'} tw-top-0 tw-bottom-0`}
              style={{ backgroundColor, borderRadius: rtlStyles ? '0 16px 16px 0' : '16px 0 0 16px' }}
            ></div>
          </div>
          <p dir={dir} className={componentStyle.text} style={{ color: textColor ?? colors['C2'] }}>
            {contentText}
          </p>
          <div
            className={componentStyle.time}
            style={{ color: textColor ?? colors['C2'], ...(rtlStyles ? { left: '12px' } : { right: '12px' }) }}
          >
            {time}
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationInboxCardItem
