import React, { useContext } from 'react'
import Modal from '../Modal'
import colorsContext from '../../contexts/ColorsContext'
import SecondaryButton from '../../atomicComponents/atoms/SecondaryButton/SecondaryButton'
import useTranslatedText from '../../hooks/useTranslatedText'
import componentStyle from './ConfirmationModal.module.scss'
const ConfirmationModal = ({
  closeModal,
  text,
  cancelText,
  confirmText,
  onConfirm,
  children,
  hasBoxContent = true
}) => {
  const { colors } = useContext(colorsContext)
  const { dir } = useTranslatedText()

  return (
    <Modal closeModal={closeModal}>
      {hasBoxContent ? (
        <div className='confirm-modal modal-type-1' style={{ background: colors['C183'] }}>
          <div className='modal-content' style={{ color: colors['C2'] }}>
            <p dir={dir} className='res:fs-md'>
              {text}
            </p>
          </div>
          <div className='d-flex justify-content-evenly'>
            {cancelText && (
              <SecondaryButton style={{ background: colors['C73'], color: colors['C589'] }} onClick={closeModal}>
                {cancelText}
              </SecondaryButton>
            )}
            {confirmText && (
              <SecondaryButton
                onClick={() => {
                  onConfirm()
                  closeModal()
                }}
                style={{ background: colors['C73'], color: colors['C589'] }}
              >
                {confirmText}
              </SecondaryButton>
            )}
          </div>
        </div>
      ) : (
        <div className={componentStyle.hasNoBox}>{children}</div>
      )}
    </Modal>
  )
}

export default ConfirmationModal
