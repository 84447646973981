import StudyNewBadgeSvg from '../../../icons/StudyTypeBadges/StudyNewBadgeSvg'
import StudyRepeatBadgeSvg from '../../../icons/StudyTypeBadges/StudyRepeatBadgeSvg'
import StudyReviewBadgeSvg from '../../../icons/StudyTypeBadges/StudyReviewBadgeSvg'
import componentStyle from './TodayStudyTypeBadge.module.scss'
import classNames from 'classnames'
import StudyCardsBadgeSvg from '../../../icons/StudyTypeBadges/StudyCardsBadgeSvg'
import StudyNoticeBadgeSvg from '../../../icons/StudyTypeBadges/StudyNoticeBadgeSvg'
import { NotificationBadgeType } from 'services/api/notification/types'

type Props = {
  type: NotificationBadgeType
  size?: 'medium' | 'large'
}

const TodayStudyTypeBadge = ({ type, size }: Props) => {
  const badgeClassNames = classNames({
    [componentStyle.medium]: size === 'medium',
    [componentStyle.large]: size === 'large'
  })

  const renderIcon = () => {
    switch (type) {
      case NotificationBadgeType.new:
        return <StudyNewBadgeSvg />
      case NotificationBadgeType.repeat:
        return <StudyRepeatBadgeSvg />
      case NotificationBadgeType.review:
        return <StudyReviewBadgeSvg />
      case NotificationBadgeType.cards:
        return <StudyCardsBadgeSvg />
      case NotificationBadgeType.notice:
      default:
        return <StudyNoticeBadgeSvg />
    }
  }

  return (
    <div className={componentStyle.badge}>
      <div className={badgeClassNames}>{renderIcon()}</div>
    </div>
  )
}

// TodayStudyTypeBadge.propTypes = {
//   type: PropTypes.oneOf(['new', 'repeat', 'review', 'cards', 'notice'])
// }

// TodayStudyTypeBadge.defaultProps = {
//   type: 'new'
// }

export default TodayStudyTypeBadge
