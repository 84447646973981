import { toNumber } from "lodash"
import { NotificationInboxCountServiceModel } from "services/api/notification/types"

const notificationsInboxCountMapper = (data: NotificationInboxCountServiceModel) => {
  return {
    new: toNumber(data?.NEW) as number,
    memoryBox: toNumber(data?.MEMORY_BOX) as number,
    announcements: toNumber(data?.ANNOUNCEMENTS) as number,
    repeat: toNumber(data?.REPEAT) as number,
    review: toNumber(data?.REVIEW) as number,
    renewal: toNumber(data?.ACCOUNT_RENEWAL) as number
  }
}

export type NotificationInboxCountState = ReturnType<typeof notificationsInboxCountMapper>

export default notificationsInboxCountMapper