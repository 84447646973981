import * as Sentry from '@sentry/react'
import { initializeApp } from 'firebase/app'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { getNotificationBadgeTypeByClass } from 'services/mapper/messages/notificationInboxMapper'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
  // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const serviceWorkerAvailable = 'serviceWorker' in navigator

// Initialize Firebase
let messaging

if (serviceWorkerAvailable) {
  const firebaseApp = initializeApp(firebaseConfig)
  messaging = getMessaging(firebaseApp)
}

// Request permission to generate a token
const requestPermission = async () => {
  if ('Notification' in window) {
    const np = Notification.permission
    if (np === 'granted' || np === 'denied') {
      return np
    }
    return await Notification.requestPermission()
  }
  return 'not-available'
}

// Get Token From Cloud Messaging
export const getFirebaseMessagingToken = async setTokenFound => {
  if (messaging) {
    return requestPermission().then(permission => {
      if (permission === 'granted') {
        return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
          .then(currentToken => {
            if (currentToken) {
              setTokenFound(true)
              return currentToken
            } else {
              setTokenFound(false)
              return null
            }
          })
          .catch(err => {
            // catch error while creating client token
            Sentry.captureMessage('Get firebase token has got an issue', {
              level: 'error',
              extra: { errorMessage: typeof err === 'object' ? JSON.stringify(err) : err }
            })
          })
      } else {
        // console.log("User Notification Permission Denied.");
      }
    })
  }

  return null
}

if (messaging) {
  onMessage(messaging, payload => {
    let link = ''

    try {
      const url = new URL(payload.fcmOptions?.link)
      link = payload.fcmOptions.link.replace(url.origin, '')
    } catch {
      link = '/notifications/ALL'
    }

    const event = new CustomEvent('fcmMessage', {
      detail: {
        title: payload.notification?.title ?? '',
        message: payload.notification?.body,
        notificationClass: payload.data?.notificationCategory,
        badgeType: getNotificationBadgeTypeByClass(payload.data?.notificationClass),
        link
      }
    })

    document.dispatchEvent(event)
  })
}
