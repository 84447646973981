import numberToEnglishText from '../../operations/numberToEnglishText'
import wordsToNumbers from 'words-to-numbers'
import wordsToNumberParser from '../../util/wordsToNumber/wordsToNumberParser'
import { orderBy } from 'lodash'
import twoDigitNumber from '../../operations/twoDigitNumber'
import { areTimesEqual } from '../../util/utility'

const findNumbersInString = str => {
  const regex = /\d+/g
  const matches = [...str.matchAll(regex)]

  return matches.map(match => ({
    start: match.index,
    end: match.index + match[0].length,
    value: match[0],
    type: 'digits'
  }))
}

const extractNumbers = str => {
  let inputSpelledOutNumbers = wordsToNumberParser(str, { impliedHundreds: false }) ?? []
  inputSpelledOutNumbers = inputSpelledOutNumbers.filter(it => it.value !== 'a')
  let inputDigitNumbers = findNumbersInString(str) ?? []
  return orderBy([...inputSpelledOutNumbers, ...inputDigitNumbers], 'start', 'desc')
}

const matchTimes = (str, numbers) => {
  const quarterPastRegex = /\b(?:a\s)?quarter\s(?:past|after)\b/gi // a quarter past four = 4:15
  const quarterToRegex = /\b(?:a\s)?quarter\sto\b/gi // a quarter to four = 3:45
  const halfPastRegex = /\b(?:a\s)?half\spast\b/gi // a half past four = 4:30
  const oClockRegex = /\bo(?:'|\s)clock\b/gi // four o'clock = 4:00
  const atRegex = /\bat(?= \d+|\s[a-zA-Z]+)(?! \d+(?::\d+|\.\d+)? o'clock\b)/gi

  const pastRegex = /(\b(?:half|quarter)\s)?past(?=\s+\w+)/gi // twenty-five past four = 4:25
  const toRegex = /(\bquarter\s|\d:\d\d\s)?to(?=\s+\w+)/gi // twenty-five to four = 3:35

  const quarterPastMatches = [...str.matchAll(quarterPastRegex)].map(it => ({
    ...it,
    end: it.index + it[0].length,
    type: 'quarter-past-h'
  }))
  const quarterToMatches = [...str.matchAll(quarterToRegex)].map(it => ({
    ...it,
    end: it.index + it[0].length,
    type: 'quarter-to-h'
  }))
  const halfPastMatches = [...str.matchAll(halfPastRegex)].map(it => ({
    ...it,
    end: it.index + it[0].length,
    type: 'half-past-h'
  }))
  const oClockMatches = [...str.matchAll(oClockRegex)].map(it => ({
    ...it,
    end: it.index + it[0].length,
    type: 'h-o-clock'
  }))
  const atMatches = [...str.matchAll(atRegex)].map(it => ({
    ...it,
    end: it.index + it[0].length,
    type: 'at-h'
  }))
  const pastMatches = [...str.matchAll(pastRegex)]
    .filter(match => !match[1]) // Exclude matches where the unwanted pattern is found
    .map(it => ({
      ...it,
      end: it.index + it[0].length,
      type: 'm-past-h'
    }))
  const toMatches = [...str.matchAll(toRegex)]
    .filter(match => !match[1]) // Exclude matches where the unwanted pattern is found
    .map(it => ({
      ...it,
      end: it.index + it[0].length,
      type: 'm-to-h'
    }))

  let matches = [
    ...quarterPastMatches,
    ...quarterToMatches,
    ...halfPastMatches,
    ...oClockMatches,
    ...atMatches,
    ...pastMatches,
    ...toMatches
  ]

  matches = orderBy(matches, 'index', 'desc')

  let result = {
    string: str,
    matches: []
  }

  const getNumberAfter = match => {
    return numbers.find(number => number.start === match.end + 1)
  }
  const getNumberBefore = match => {
    return numbers.find(number => number.end === match.index - 1)
  }
  const getValue = obj => {
    let value = obj.value

    if (obj.type === 'words') {
      value = wordsToNumbers(obj.value)
    }

    return value
  }

  // Convert user answer times format to HH:MM
  matches.forEach(match => {
    if (match.type === 'quarter-past-h') {
      const hour = getNumberAfter(match)

      if (hour) {
        let hourValue = getValue(hour)

        const replacement = `${hourValue}:15`
        result.string = result.string.replaceBetween(match.index, hour.end, replacement)
        result.matches.push({
          original: {
            start: match.index,
            end: hour.end,
            value: match[0] + ' ' + hour.value
          },
          converted: {
            start: match.index,
            end: match.index + replacement.length,
            value: replacement
          }
        })
      }
    } else if (match.type === 'quarter-to-h') {
      const hour = getNumberAfter(match)

      if (hour) {
        let hourValue = getValue(hour)

        hourValue = parseInt(hourValue) - 1

        const replacement = `${hourValue}:45`
        result.string = result.string.replaceBetween(match.index, hour.end, replacement)
        result.matches.push({
          original: {
            start: match.index,
            end: hour.end,
            value: match[0] + ' ' + hour.value
          },
          converted: {
            start: match.index,
            end: match.index + replacement.length,
            value: replacement
          }
        })
      }
    } else if (match.type === 'half-past-h') {
      const hour = getNumberAfter(match)

      if (hour) {
        let hourValue = getValue(hour)

        const replacement = `${hourValue}:30`
        result.string = result.string.replaceBetween(match.index, hour.end, replacement)
        result.matches.push({
          original: {
            start: match.index,
            end: hour.end,
            value: match[0] + ' ' + hour.value
          },
          converted: {
            start: match.index,
            end: match.index + replacement.length,
            value: replacement
          }
        })
      }
    } else if (match.type === 'h-o-clock') {
      const hour = getNumberBefore(match)

      if (hour) {
        let hourValue = getValue(hour)

        const replacement = `${hourValue}:00`
        result.string = result.string.replaceBetween(hour.start, match.end, replacement)
        result.matches.push({
          original: {
            start: hour.start,
            end: match.end,
            value: hour.value + ' ' + match[0]
          },
          converted: {
            start: match.index,
            end: hour.start + replacement.length,
            value: replacement
          }
        })
      }
    } else if (match.type === 'at-h') {
      const hour = getNumberAfter(match)
      const numAfterHour = getNumberAfter(hour)

      if (hour && !numAfterHour) {
        let hourValue = getValue(hour)

        const replacement = `at ${hourValue}:00`
        result.string = result.string.replaceBetween(match.index, hour.end, replacement)
        result.matches.push({
          original: {
            start: match.index,
            end: hour.end,
            value: match[0] + ' ' + hour.value
          },
          converted: {
            start: match.index,
            end: match.index + replacement.length,
            value: replacement
          }
        })
      }
    } else if (match.type === 'm-past-h') {
      const hour = getNumberAfter(match)
      const minute = getNumberBefore(match)

      if (hour && minute) {
        let hourValue = getValue(hour)
        let minuteValue = getValue(minute)

        hourValue = twoDigitNumber(hourValue)
        minuteValue = twoDigitNumber(minuteValue)

        const replacement = `${hourValue}:${minuteValue}`
        result.string = result.string.replaceBetween(minute.start, hour.end, replacement)
        result.matches.push({
          original: {
            start: minute.start,
            end: hour.end,
            value: minute.value + ' ' + match[0] + ' ' + hour.value
          },
          converted: {
            start: minute.start,
            end: minute.start + replacement.length,
            value: replacement
          }
        })
      }
    } else if (match.type === 'm-to-h') {
      const hour = getNumberAfter(match)
      const minute = getNumberBefore(match)

      if (hour && minute) {
        let hourValue = getValue(hour)
        let minuteValue = getValue(minute)

        hourValue = parseInt(hourValue) - 1
        minuteValue = 60 - parseInt(minuteValue)

        minuteValue = twoDigitNumber(minuteValue)

        const replacement = `${hourValue}:${minuteValue}`
        result.string = result.string.replaceBetween(minute.start, hour.end, replacement)
        result.matches.push({
          original: {
            start: minute.start,
            end: hour.end,
            value: minute.value + ' ' + match[0] + ' ' + hour.value
          },
          converted: {
            start: minute.start,
            end: minute.start + replacement.length,
            value: replacement
          }
        })
      }
    }
  })

  return result
}

const matchStrings = (input, given) => {
  let inputExtractedNumbers = extractNumbers(input)
  let givenExtractedNumbers = extractNumbers(given)

  // If there is a time (with format: HH:MM) in given, do process to convert time in user answer
  const timeRegex = /\b([01]?[0-9]|2[0-3]):[0-5][0-9]\b/g
  if (given.match(timeRegex)) {
    input = matchTimes(input, inputExtractedNumbers).string
    inputExtractedNumbers = extractNumbers(input)
  } else {
    const inputTimeMatches = input.match(timeRegex)

    if (inputTimeMatches && inputTimeMatches?.length > 0) {
      const givenWithDigitTimes = matchTimes(given, givenExtractedNumbers).matches

      if (givenWithDigitTimes?.length > 0) {
        inputTimeMatches?.reverse()?.forEach((inputTimeMatch, i) => {
          const givenOriginalTime = givenWithDigitTimes?.[i]?.original?.value // Five o'clock
          const givenConvertedTime = givenWithDigitTimes?.[i]?.converted?.value // 5:00

          if (givenConvertedTime && areTimesEqual(givenConvertedTime, inputTimeMatch)) {
            input = input.replace(inputTimeMatch, givenOriginalTime)
            inputExtractedNumbers = extractNumbers(input)
          }
        })
      }
    }
  }

  let result = input

  inputExtractedNumbers.forEach((inputNumber, i) => {
    const givenNumber = givenExtractedNumbers[i]
    if (givenNumber) {
      if (givenNumber.type !== inputNumber.type) {
        // Input number format is "digits" like: "23"
        if (inputNumber.type === 'digits') {
          // Convert givenNumber words value to numeric value
          let givenNumberDigits = wordsToNumbers(givenNumber.value, { impliedHundreds: false })

          // Convert givenNumberDigits value type to string
          if (typeof givenNumberDigits === 'number') {
            givenNumberDigits = givenNumberDigits.toString()
          }

          // If we have a time like (4:25) but the given type is like (four twenty-five),
          // the following line will remove ":" between numbers
          if (result.charAt(inputNumber.end) === ':') {
            result = result.replaceBetween(inputNumber.end, inputNumber.end + 1, ' ')
          }

          // Check if input number is equal to given number: keep given number
          // Else: convert input number value to words
          if (givenNumberDigits === inputNumber.value) {
            result = result.replaceBetween(inputNumber.start, inputNumber.end, givenNumber.value.toLowerCase())
          } else {
            result = result.replaceBetween(
              inputNumber.start,
              inputNumber.end,
              numberToEnglishText(inputNumber.value).toLowerCase()
            )
          }
        } else {
          // Input number format is "words" like: "twenty-three"
          // Convert inputNumber words value to numeric value
          let inputNumberDigits = wordsToNumbers(inputNumber.value, { impliedHundreds: false })

          // Convert inputNumberDigitType value type to string
          if (typeof inputNumberDigits === 'number') {
            inputNumberDigits = inputNumberDigits.toString()
          }

          // Convert input number value to digits
          result = result.replaceBetween(inputNumber.start, inputNumber.end, inputNumberDigits)
        }
      } else {
        const givenNumberValue = givenNumber.value.toLowerCase()
        const inputNumberValue = inputNumber.value.toLowerCase()

        if (inputNumber.type === 'words' && givenNumberValue !== inputNumberValue) {
          // Convert given and input words value to numeric value
          let givenNumberDigits = wordsToNumbers(givenNumber.value, { impliedHundreds: false })
          let inputNumberDigits = wordsToNumbers(inputNumber.value, { impliedHundreds: false })

          if (givenNumberDigits === inputNumberDigits) {
            result = result.replaceBetween(inputNumber.start, inputNumber.end, givenNumberValue)
          }
        }
      }
    }
  })

  return result
}

export const processSpeechText = (input, given) => {
  if (typeof input === 'string' && input.length > 0 && typeof given === 'string' && given.length > 0) {
    // Convert numbers and time formats in input based on given
    input = matchStrings(input, given)

    // Convert "[number] (x|times|by|in) [number]" to "[number]x[number]"
    input = input.replace(/(\d)(\s*)(am|pm)\b/gi, (match, number, space, period) => `${number} ${period}.m.`)

    // Convert "[number] (x|times|by|in) [number]" to "[number]x[number]"
    input = input.replace(/(\d+)\s*(x|times|by|in)\s*(\d+)/gi, '$1×$3')

    // Convert centimeters to cm
    input = input.replace(/centimeters\b/gi, 'cm')

    // Convert "[num] kilometers" to "[num] km"
    input = input.replace(/(\d+)\s*kilometers\b/gi, '$1 km')

    // Convert km per hour to km/h
    input = input.replace(/(km|kilometers|kilometer) per hour\b/gi, 'km/h')

    // Convert "[number] feet [number] inches" to "[number]'[number]''"
    input = input.replace(/(\d+)\s*(ft|feet)\s+(\d+)\s*(inches|in)\b/gi, '$1\'$3"')

    // Convert "[number].[number]." to "[number]:[number]" (up to 24:00) except if followed by 'billion', 'million', or 'thousand'
    input = input.replace(/(\d{1,2})\.(\d{1,2})(?! billion\b)(?! million\b)(?! thousand\b)/g, (match, p1, p2) => {
      let hours = parseInt(p1)
      let minutes = parseInt(p2)
      return hours < 24 && minutes < 60 ? `${p1}:${p2}` : match
    })
  }

  return input
}
