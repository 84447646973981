import { useEffect } from 'react'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { seenNotificationById } from 'redux/features/messages/messagesCategorySlice'
import { removeQueryParamsAndNavigate } from 'util/utility'
import { useLocation } from 'react-router'

/**
 * A functional component which initiates just once in App
 * This component is right under the BrowserRouter component from `react-router-dom`
 *
 * @return {JSX.Element} An empty JSX element
 */
const AppInRouter = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const checkNotificationId = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const notificationId = urlParams.get('seenNotificationId')
    if (notificationId) {
      dispatch(seenNotificationById({ id: notificationId }))
      removeQueryParamsAndNavigate(['seenNotificationId'])
    }
  }

  useEffect(() => {
    checkNotificationId()
  }, [location])

  return <></>
}

export default AppInRouter
