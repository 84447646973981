import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import componentStyle from './PageLayer.module.scss'
import OverlayScrollbar from '../../layouts/OverlayScrollbar'
import classNames from 'classnames'
import useBodyScrollLock from '../../hooks/useBodyScrollLock'

const PageLayer = ({ close, children, background }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = null
    }
  }, [])
  useBodyScrollLock(true)

  return ReactDOM.createPortal(
    <div className={classNames(componentStyle.pageLayer)} style={{ background }}>
      <OverlayScrollbar>{children}</OverlayScrollbar>
    </div>,
    document.getElementById('page-layer')
  )
}

export default PageLayer
