import React, { useContext } from 'react'
import Navbar from '../Navbar'
import { withRouter } from '../../hooks/withRouter'
import { Link, useNavigate } from 'react-router-dom'
import BackPageSvg from '../../icons/BackPageSvg'
import HeaderWizard from '../HeaderWizard/HeaderWizard'
import WordMarkLogoSvg from '../../icons/logo/WordMarkLogoSvg'
import colorsContext from '../../contexts/ColorsContext'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import PictorialMarkLogoSvg from '../../icons/logo/PictorialMarkLogoSvg'
import useTranslatedText from '../../hooks/useTranslatedText'

type Props = {
  title?: string
  levels?: number|string
  currentLevel?: number|string
  currentLevelName?: string
  steps?: string|number
  currentStep?: string|number
  headerStyle?: string
  withBackButton?: boolean
  withNavbar?: boolean
  leftItems?: any
  rightItems?: any
  onClickBackButton?: any
  hideRightItemsInMobile?: boolean
}

const Header = ({
  title,
  levels,
  currentLevel,
  currentLevelName,
  steps,
  currentStep,
  headerStyle,
  withBackButton,
  withNavbar,
  leftItems,
  rightItems,
  onClickBackButton,
  hideRightItemsInMobile = false
}: Props) => {
  const navigate = useNavigate()
  const { colors }: any = useContext(colorsContext)
  const { dir } = useTranslatedText() // rtl, ltr
  const { windowWidth } = useWindowDimensions()

  const renderTitle = () => {
    if (title) {
      return (
        <h1 className='header-title' style={{ color: /*titleColor ??*/ colors['C179'] }}>
          <span dir={dir}>{title}</span>
        </h1>
      )
    } else {
      return null
    }
  }

  const renderWizard = () => {
    if (currentLevel) {
      return (
        <div className='d-none d-md-block'>
          <HeaderWizard
            currentLevel={currentLevel}
            steps={steps}
            levels={levels}
            currentStep={currentStep}
            currentLevelName={currentLevelName}
            headerStyle={headerStyle}
          />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className='header-offset' style={{ height: '65px' }}>
      <div className='header w-100 position-fixed' style={{ background: colors['C560'] }}>
        <div className='header-container'>
          <div className='header-left-items'>
            <div className='header-logo'>
              <Link to='/' className='desktop-logo'>
                <WordMarkLogoSvg
                  primaryColor={colors['C179']}
                  oInnerColor='transparent'
                  secondaryColor={colors['C571']}
                />
              </Link>
              <Link to='/' className='mobile-logo'>
                <PictorialMarkLogoSvg
                  primaryColor={colors['C179']}
                  oInnerColor='transparent'
                  secondaryColor={colors['C571']}
                />
              </Link>
              {/*<div className={componentStyle.betaBadge}><BetaBadge/></div>*/}
            </div>
            {withNavbar && (
              <div className='header-menu-button'>
                <Navbar backgroundColor={/*navbarColor*/ colors['C179']} />
              </div>
            )}
            {withBackButton && (
              <div className='header-arrow-back'>
                <button
                  onClick={() => {
                    if (onClickBackButton) {
                      onClickBackButton()
                    } else {
                      navigate(-1)
                    }
                  }}
                >
                  <BackPageSvg color={/*iconsColor*/ colors['C179']} />
                </button>
              </div>
            )}
            {leftItems}
          </div>
          {renderTitle()}
          {renderWizard()}
          {(!hideRightItemsInMobile || windowWidth >= 768) && (
            <div className='header-right-items'>
              {rightItems}
              {/*<Link to="/" className="select-language-box">*/}
              {/*FA*/}
              {/*</Link>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withRouter(Header)
