import { createSlice } from '@reduxjs/toolkit'
import todayStudyUseCase from '../../useCase/todayStudyUseCase/todayStudyUseCase'
import { orderBy } from 'lodash'
import homeTodayStudyUseCase from '../../useCase/homepageServices/homeTodayStudyUseCase'
import todayStudyPlanUseCase from '../../useCase/todayStudyUseCase/todayStudyPlanUseCase'
import { skipToNextStudyPlan } from '../../services/cloudServices'
import skipStudyPlanUseCase from '../../useCase/todayStudyUseCase/skipStudyPlanUseCase'
import { baseAsyncThunk } from '../baseAsyncThunk'

const initialState = {
  skipToNextPlanLoading: false,
  studyScheduleLoading: false,
  studySchedule: [],
  studyScheduleHasNext: false,
  studySchedulePageIndex: 0,
  highlightedPlanCard: null,
  todayStudyPlans: null,
  displayingPlans: null,
  convertToNextTodayStudyCard: false,
  readyToRenderNextPlan: false,
  renderNextPlans: false,
  readyMemoryCards: [],
  quickReview: false,
  quickReviewUnits: [],
  celebrating: null,
  error: null,
  loading: false
}

export const getTodayStudyPlan = baseAsyncThunk('todayStudy/getTodayStudyPlan', (arg, thunkAPI) => {
  const { todayStudy } = thunkAPI.getState()
  return todayStudyPlanUseCase({
    baseData: arg?.rebase ? [] : todayStudy.studySchedule,
    pageIndex: arg?.rebase ? 0 : todayStudy.studySchedulePageIndex
  })
})

export const postSkipStudyPlan = baseAsyncThunk('todayStudy/postSkipStudyPlan', () => skipStudyPlanUseCase())

export const getTodayStudy = baseAsyncThunk('todayStudy/getTodayStudy', () => {
  return todayStudyUseCase()
})

export const getHomeTodayStudy = baseAsyncThunk('homepage/getHomeTodayStudy', (arg, thunkAPI) => {
  const { appUser } = thunkAPI.getState()
  return homeTodayStudyUseCase({ hasPlan: appUser?.appUserData?.access.hasPlan })
})

const todayStudySlice = createSlice({
  name: 'todayStudy',
  initialState,
  reducers: {
    setCelebrating: (state, action) => {
      state.celebrating = action.payload
    },
    setConvertToNextTodayStudyCard: (state, action) => {
      state.convertToNextTodayStudyCard = action.payload
    },
    setReadyToRenderNextPlan: (state, action) => {
      state.readyToRenderNextPlan = action.payload
    },
    setReadyMemoryCards: (state, action) => {
      state.readyMemoryCards = action.payload ?? []
    },
    sortQuickReviewUnits: (state, action) => {
      if (action.payload.sortBy === 'UNIT') {
        state.quickReviewUnits = orderBy(state.quickReviewUnits, 'unitId', action.payload.orderBy?.toLowerCase())
      }
    },
    setRenderNextPlans: (state, action) => {
      if (action.payload) {
        state.displayingPlans = state.todayStudyPlans?.nextDay
      } else {
        state.displayingPlans = state.todayStudyPlans?.currentDay
      }
    },
    setHighlightedPlanCard: (state, action) => {
      state.highlightedPlanCard = action.payload
    },
    loadMorePlanCards: state => {
      if (state.studyScheduleHasNext && !state.studyScheduleLoading) state.studySchedulePageIndex++
    },
    clearStudyScheduleStates: state => {
      state.studyScheduleLoading = false
      state.studySchedule = []
      state.studyScheduleHasNext = false
      state.studySchedulePageIndex = 0
    }
  },

  extraReducers: builder => {
    /*
     * Today's study
     */
    builder
      .addCase(getHomeTodayStudy.pending, state => {
        state.todayStudyLoading = true
      })
      .addCase(getHomeTodayStudy.fulfilled, (state, action) => {
        state.todayStudyLoading = false
        state.todayStudyPlans = action.payload
        state.displayingPlans = action.payload.currentDay
      })
      .addCase(getHomeTodayStudy.rejected, (state, action) => {
        state.todayStudyLoading = false
        state.error = action.error.message
      })

    builder
      .addCase(getTodayStudyPlan.pending, state => {
        state.studyScheduleLoading = true
      })
      .addCase(getTodayStudyPlan.fulfilled, (state, action) => {
        state.studyScheduleLoading = false
        state.studySchedule = action.payload.plans
        state.studyScheduleHasNext = action.payload.hasNext
      })
      .addCase(getTodayStudyPlan.rejected, (state, action) => {
        state.studyScheduleLoading = false
        state.error = action.error.message
      })

    builder
      .addCase(getTodayStudy.pending, state => {
        state.todayStudyLoading = true
      })
      .addCase(getTodayStudy.fulfilled, (state, action) => {
        state.todayStudyLoading = false
        state.todayStudyPlans = action.payload.todayStudy
        state.displayingPlans = action.payload.todayStudy.currentDay
        state.quickReviewUnits = action.payload.quickReview
      })
      .addCase(getTodayStudy.rejected, (state, action) => {
        state.todayStudyLoading = false
        state.error = action.error.message
      })

    builder
      .addCase(postSkipStudyPlan.pending, state => {
        state.skipToNextPlanLoading = true
      })
      .addCase(postSkipStudyPlan.fulfilled, (state, action) => {
        state.skipToNextPlanLoading = false
      })
      .addCase(postSkipStudyPlan.rejected, (state, action) => {
        state.skipToNextPlanLoading = false
        state.error = action.error.message
      })
  }
})

export const {
  setReadyMemoryCards,
  sortQuickReviewUnits,
  setRenderNextPlans,
  setConvertToNextTodayStudyCard,
  setReadyToRenderNextPlan,
  setHighlightedPlanCard,
  loadMorePlanCards,
  clearStudyScheduleStates,
  setCelebrating
} = todayStudySlice.actions
export default todayStudySlice.reducer
