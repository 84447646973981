import { createSlice } from '@reduxjs/toolkit'
import serviceCall from '../../services'
import { every, filter, find, maxBy, some } from 'lodash'
import checkComboBoxMapper from '../../services/mapper/exercise/checkComboBoxMapper'
import checkDragAndDropMapper from '../../services/mapper/exercise/checkDragAndDropMapper'
import checkMultipleChoiceMapper from '../../services/mapper/exercise/checkMultipleChoiceMapper'
import checkPutInOrderMapper from '../../services/mapper/exercise/checkPutInOrderMapper'
import checkPutInOrderConversationMapper from '../../services/mapper/exercise/checkPutInOrderConversationMapper'
import checkMatchMapper from '../../services/mapper/exercise/checkMatchMapper'
import checkChooseFromCategoryMapper from '../../services/mapper/exercise/checkChooseFromCategoryMapper'
import checkTypeTheAnswerMapper from '../../services/mapper/exercise/checkTypeTheAnswerMapper'
import checkSayTheFollowingMapper from '../../services/mapper/exercise/checkSayTheFollowingMapper'
import checkDragAndDropConversationMapper from '../../services/mapper/exercise/checkDragAndDropConversationMapper'
import checkDragAndDropCategoryMapper from '../../services/mapper/exercise/checkDragAndDropCategoryMapper'
import fetchExerciseMapperSwitch from '../../services/mapper/exercise/fetchExerciseMapperSwitch'
import sectionExerciseUseCase from '../../useCase/sectionExercise/sectionExerciseUseCase'
import { TYPE_A, TYPE_B } from '../../enums/exerciseEnums/exerciseGroupTypeEnums'
import checkExerciseUseCase from '../../useCase/checkExerciseUseCase/checkExerciseUseCase'
import { playCheckSound } from '../../util/playCheckSound/playCheckSound'
import { baseAsyncThunk } from '../baseAsyncThunk'
import { ContentTypesStructureEnums } from '../../enums/structureEnums/templateType'
import { AnswerStatusEnums, INLINE } from '../../enums/globalEnums/globalEnums'
import exerciseTypeEnums from 'enums/exerciseEnums/exerciseEnums'

const initialState = {
  loading: false,
  fetchSectionExerciseLoading: false,
  pageType: null,
  checkingItem: false,
  exerciseItemUpdated: false,
  data: {},
  exerciseGroupType: TYPE_A,
  isSingleTemplate: false,
  currentItem: {
    stackIndex: 0,
    itemIndex: 0,
    pageNumber: 1
  },
  finished: false,
  continuable: false,
  spellPending: false,
  error: '',
  lastDataDetail: {
    unitNumber: null,
    sectionNumber: null,
    groupType: null
  }
}

export const fetchSectionExercise = baseAsyncThunk(
  'exercise/fetchSectionExercise',
  ({ unit, section, sectionNumber }, { getState }) => {
    const { exercise } = getState()
    return sectionExerciseUseCase({
      unit,
      section,
      sectionNumber,
      groupType: exercise.exerciseGroupType
    })
  }
)

export const speechToText = baseAsyncThunk('exercise/speechToText', ({ formData }) => {
  return serviceCall('spell', { formData }, 'cloud')
})

export const checkExerciseItem = baseAsyncThunk(
  'exercise/checkExerciseItem',
  ({ userAnswerData, isVoiceRecognition }) => {
    return checkExerciseUseCase({ data: userAnswerData, isVoiceRecognition })
  }
)

// Returns check mapper according to exercise type
const getDataMergedWithCheckResponse = (stackId, response, data) => {
  const responseData = response.exerciseCheckItemResponse ?? []

  switch (data.type) {
    case 'COMBO_BOX':
      return checkComboBoxMapper(stackId, responseData, data)
    case 'COMBO_PICTIONARY':
      return checkComboBoxMapper(stackId, responseData, data)
    case 'CHOOSE_FROM_CATEGORY':
      return checkChooseFromCategoryMapper(stackId, responseData, data)
    case 'MATCH_CASE':
      return checkMatchMapper(stackId, responseData, data)
    case 'MULTIPLE_CHOICE':
      return checkMultipleChoiceMapper(stackId, responseData, data)
    case 'SAY_THE_FOLLOWING':
      return checkSayTheFollowingMapper(stackId, responseData, data)
    case 'TYPE_THE_ANSWER':
      return checkTypeTheAnswerMapper(stackId, responseData, data)
    case 'DRAG_AND_DROP_CATEGORY':
      return checkDragAndDropCategoryMapper(stackId, responseData, data)
    case 'DRAG_AND_DROP':
      return checkDragAndDropMapper(stackId, responseData, data)
    case 'DRAG_AND_DROP_CONVERSATION':
      return checkDragAndDropConversationMapper(stackId, responseData, data)
    case 'PUT_IN_ORDER':
      return checkPutInOrderMapper(stackId, responseData, data)
    case 'PUT_IN_ORDER_CONVERSATION':
      return checkPutInOrderConversationMapper(stackId, responseData, data)
    default:
      return response
  }
}

const exerciseSlice = createSlice({
  name: 'exerciseSection',
  initialState,
  reducers: {
    setSectionExercise: (state, action) => {
      const response = fetchExerciseMapperSwitch(action.payload.sectionData)
      state.data = response.data
      state.pageType = response.pageType
    },
    changeGroupType: (state, action) => {
      if (action.payload === TYPE_A || action.payload === TYPE_B) {
        state.exerciseGroupType = action.payload
      } else if (state.exerciseGroupType === TYPE_A) {
        state.exerciseGroupType = TYPE_B
      } else {
        state.exerciseGroupType = TYPE_A
      }
    },
    onClickOptionCFC: (state, action) => {
      state.data.stack = {
        ...state.data.stack,
        choices: [...state.data.stack.choices].map(choice => {
          if (action.payload.index === choice.index) {
            return {
              ...choice,
              selected: !choice.selected
            }
          }
          return choice
        })
      }
      const checkable = some(state.data.stack.choices, 'selected')
      state.data = { ...state.data, checkable }
    },
    toggleLockCFC: state => {
      state.data.unlocked = !state.data.unlocked
    },
    selectOptionPIOC: (state, action) => {
      const maxOrderNumber = maxBy(state.data.stack.givens, o => o.order).order

      state.data.stack = {
        ...state.data.stack,
        givens: [...state.data.stack.givens].map(given => {
          if (given.index === action.payload.givenIndex) {
            return { ...given, order: maxOrderNumber + 1, selected: true }
          }
          return given
        })
      }
      const checkable = every(state.data.stack.givens, 'selected')
      state.data = { ...state.data, checkable }
    },
    unselectOptionPIOC: (state, action) => {
      state.data.stack = {
        ...state.data.stack,
        givens: [...state.data.stack.givens].map(given => {
          if (given.index === action.payload.givenIndex) {
            return { ...given, selected: false }
          }
          return given
        })
      }
      const checkable = every(state.data.stack.givens, 'selected')
      state.data = { ...state.data, checkable }
    },
    selectOptionPIO: (state, action) => {
      const selectedChoice = find(
        state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].choices,
        obj => {
          return obj.index === action.payload.choiceIndex
        }
      )
      if (selectedChoice) {
        state.data.stacks[action.payload.stackIndex].items = [
          ...state.data.stacks[action.payload.stackIndex].items
        ].map((item, i) => {
          if (i === action.payload.itemIndex) {
            return {
              ...item,
              choices: [...item.choices].map(choice => {
                if (choice.index === action.payload.choiceIndex) {
                  return { ...choice, selected: true }
                }
                return choice
              }),
              userChoices: [...item.userChoices, selectedChoice]
            }
          } else {
            return item
          }
        })
        state.exerciseItemUpdated = true

        // TODO: if there was no problem remove this two commented lines
        // const checkable = every(state.data.stacks[action.payload.stackIndex].items, obj => every(obj.choices, 'selected'))
        // state.data = {...state.data, checkable}
      }
    },
    unselectOptionPIO: (state, action) => {
      const selectedChoice = find(
        state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].choices,
        obj => {
          return obj.index === action.payload.choiceIndex
        }
      )
      if (selectedChoice) {
        state.data.stacks[action.payload.stackIndex].items = [
          ...state.data.stacks[action.payload.stackIndex].items
        ].map((item, i) => {
          if (i === action.payload.itemIndex) {
            return {
              ...item,
              choices: [...item.choices].map(choice => {
                if (choice.index === action.payload.choiceIndex) {
                  return { ...choice, selected: false }
                }
                return choice
              }),
              userChoices: [...item.userChoices].filter(obj => obj.index !== action.payload.choiceIndex)
            }
          } else {
            return item
          }
        })
        state.data = { ...state.data, checkable: false }
      }
    },
    selectOptionMC: (state, action) => {
      state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].choices = [
        ...state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].choices
      ].map(choiceItem => {
        if (choiceItem.index === action.payload.choiceIndex) {
          return {
            ...choiceItem,
            selected: !choiceItem.selected
          }
        } else {
          return {
            ...choiceItem,
            selected: false
          }
        }
      })
      state.exerciseItemUpdated = true
    },
    matchOptions: (state, action) => {
      state.data.userData = [
        ...state.data.userData,
        {
          given: {
            index: action.payload.givenIndex,
            unlocked: false
          },
          choice: {
            index: action.payload.choiceIndex,
            unlocked: false
          }
        }
      ]
      const checkable = state.data.userData.length === state.data.stack.givens.length
      state.data = { ...state.data, checkable }
    },
    unmatchOptions: (state, action) => {
      state.data.userData = filter([...state.data.userData], currentObj => {
        return currentObj.given.index !== action.payload.givenIndex
      })

      const checkable = state.data.userData.length === state.data.stack.givens.length
      state.data = { ...state.data, checkable }
    },
    selectChoiceCombo: (state, action) => {
      state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].choices = [
        ...state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].choices
      ].map(choices => {
        if (choices.index === action.payload.choicesIndex) {
          return {
            ...choices,
            items: choices.items.map(choice => {
              if (choice.index === action.payload.choiceIndex) {
                return {
                  ...choice,
                  selected: true
                }
              }
              return {
                ...choice,
                selected: false
              }
            })
          }
        }
        return choices
      })

      state.exerciseItemUpdated = true
    },
    toggleLockMatch: (state, action) => {
      state.data.userData = [...state.data.userData].map(item => {
        if (item.given.index === action.payload.givenIndex) {
          return {
            ...item,
            unlocked: !item.unlocked
          }
        }
        return item
      })
    },
    toggleLockPutInOrderConversation: state => {
      state.data = {
        ...state.data,
        unlocked: !state.data.unlocked
      }
    },
    dragDropChangeChoose: (state, action) => {
      let items = [...state.data.stacks[0].items]
      let choices = [...state.data.stacks[0].choices]
      let item = { ...action.payload.selectedItem }

      choices.map(choose => {
        if (choose.text.sentenceString === item.text.sentenceString) {
          choose.selected = false
          return null
        }
      })
      state.data.stacks[0].choices = choices

      item.selected = false
      item.isAnswered = false
      item.userAnswerStatus = ''
      item.correctAnswer = ''

      items[action.payload.i].answers[action.payload.j] = item
      state.data.stacks[0].items = items
    },
    dragDropChangeAnswer: (state, action) => {
      let items = [...state.data.stacks[0].items]
      let choices = [...state.data.stacks[0].choices]
      let item = { ...action.payload.selectedItem }

      // remove item from chooses list
      choices.map(choose => {
        if (choose.text.sentenceString === item.text.sentenceString) {
          choose.selected = true
          return null
        }
      })
      state.data.stacks[0].choices = choices

      // check if answer box was occupied before
      if (items[action.payload.i].answers[action.payload.j].selected) {
        choices.map(choose => {
          if (choose.text.sentenceString === items[action.payload.i].answers[action.payload.j].text.sentenceString) {
            choose.selected = false
            return null
          }
        })
        state.data.stacks[0].choices = choices
      }

      // add answer to answers list
      item.selected = true
      item.isAnswered = true
      item.userAnswerStatus = ''
      item.correctAnswer = ''

      items[action.payload.i].answers[action.payload.j] = item
      state.data.stacks[0].items = items

      // check all items have been answered
      let allAnswered = true
      items[action.payload.i].answers.map(answer => {
        if (answer.selected === false) {
          allAnswered = false
        }
      })
      if (allAnswered) state.data = { ...state.data, checked: false, checkable: true }
    },
    dragDropNextPage: state => {
      state.data.selectedPage = 1
    },
    toggleViewType: state => {
      state.isSingleTemplate = !state.isSingleTemplate
    },
    resetAll: state => {
      state.finished = false
      state.currentItem = {
        stackIndex: 0,
        itemIndex: 0,
        pageNumber: 1
      }
      switch (state.data.type) {
        case 'CHOOSE_FROM_CATEGORY':
          state.data = {
            ...state.data,
            checked: false,
            checkable: false,
            stack: {
              ...state.data.stack,
              choices: [...state.data.stack.choices].map(choice => ({
                ...choice,
                selected: false,
                userAnswerStatus: null
              }))
            }
          }
          break
        case 'MATCH_CASE':
          state.data = {
            ...state.data,
            checked: false,
            checkable: false,
            userData: []
          }
          break
        case 'PUT_IN_ORDER':
          state.data = {
            ...state.data,
            checked: false,
            checkable: false,
            unlocked: false,
            stacks: [...state.data.stacks].map(stack => ({
              ...stack,
              items: [...stack.items].map(item => ({
                ...item,
                unlocked: false,
                checked: false,
                userAnswer: null,
                choices: [...item.choices].map(choice => ({
                  ...choice,
                  selected: false
                })),
                userChoices: []
              }))
            }))
          }
          break
        case 'PUT_IN_ORDER_CONVERSATION':
          state.data = {
            ...state.data,
            checkable: false,
            checked: false,
            stack: {
              ...state.data.stack,
              givens: [...state.data.stack.givens].map(given => {
                return {
                  ...given,
                  selected: false,
                  order: -1,
                  userAnswerStatus: null
                }
              })
            }
          }
          break
        case 'COMBO_PICTIONARY':
          state.data.stacks = [...state.data.stacks].map(stack => ({
            ...stack,
            items: [...stack.items].map(item => ({
              ...item,
              unlocked: false,
              checked: false,
              choices: [...item.choices].map(choice => {
                return {
                  ...choice,
                  userAnswer: null,
                  items: [...choice.items].map(choiceItem => {
                    return {
                      ...choiceItem,
                      selected: false
                    }
                  })
                }
              })
            }))
          }))
          break
        case 'COMBO_BOX':
          state.data.stacks = [...state.data.stacks].map(stack => ({
            ...stack,
            items: [...stack.items].map(item => ({
              ...item,
              unlocked: false,
              checked: false,
              choices: [...item.choices].map(choice => {
                return {
                  ...choice,
                  userAnswer: null,
                  items: [...choice.items].map(choiceItem => {
                    return {
                      ...choiceItem,
                      selected: false
                    }
                  })
                }
              })
            }))
          }))
          break
        case 'MULTIPLE_CHOICE':
          state.data.stacks = [...state.data.stacks].map(stack => ({
            ...stack,
            items: [...stack.items].map(item => ({
              ...item,
              unlocked: false,
              checked: false,
              choices: [...item.choices].map(choice => {
                return {
                  ...choice,
                  selected: false
                }
              })
            }))
          }))
          break
        case 'TYPE_THE_ANSWER':
          state.data.stacks = [...state.data.stacks].map(stack => ({
            ...stack,
            items: [...stack.items].map(item => ({
              ...item,
              unlocked: false,
              checked: false,
              userAnswer: {
                userInputText:
                  item.inputType === INLINE
                    ? [...item.userAnswer.userInputText].map(o => {
                        return { ...o, userAnswerData: null, userAnswer: '' }
                      })
                    : { userAnswerData: null, userAnswer: '' },
                answerIsCorrect: null
              }
            }))
          }))
          break
        case 'SAY_THE_FOLLOWING':
          state.data.stacks = [...state.data.stacks].map(stack => ({
            ...stack,
            items: [...stack.items].map(item => ({
              ...item,
              unlocked: false,
              checked: false
            }))
          }))
          break
        case 'DRAG_AND_DROP':
          state.data = {
            ...state.data,
            finished: false,
            currentItemIndex: 0,
            stack: {
              ...state.data.stack,
              choices: [...state.data.stack.choices].map(choice => {
                return {
                  ...choice,
                  used: false
                }
              }),
              items: [...state.data.stack.items].map(item => {
                return {
                  ...item,
                  checked: item.isSolvedBefore,
                  checkable: false,
                  answerFields: [...item.answerFields].map(field => {
                    return {
                      ...field,
                      selected: false,
                      userAnswer: field.isSolvedBefore ? field.userAnswer : null,
                      userAnswerStatus: field.isSolvedBefore ? field.userAnswerStatus : null
                    }
                  })
                }
              })
            }
          }
          break
        case 'DRAG_AND_DROP_CATEGORY':
          state.data = {
            ...state.data,
            checked: false,
            checkable: false,
            unlocked: false,
            stack: {
              ...state.data.stack,
              choices: [...state.data.stack.choices].map(choice => {
                return {
                  ...choice,
                  used: false
                }
              }),
              answerFields: [...state.data.stack.answerFields].map(field => {
                return {
                  ...field,
                  selected: false,
                  correctAnswer: null,
                  userAnswer: null,
                  userAnswerStatus: null
                }
              })
            }
          }
          break
        case 'DRAG_AND_DROP_CONVERSATION':
          state.data = {
            ...state.data,
            checked: false,
            checkable: false,
            stack: {
              ...state.data.stack,
              choices: [...state.data.stack.choices].map(choice => {
                return {
                  ...choice,
                  used: false
                }
              }),
              items: [...state.data.stack.items].map(item => ({
                ...item,
                answerFields: [...item.answerFields].map(answerField => {
                  return {
                    ...answerField,
                    userAnswer: null,
                    userAnswerStatus: null
                  }
                }),
                checked: false
              }))
            }
          }
          break
        default:
          state.data = { ...state.data, checked: false }
      }
    },
    goToNextItem: state => {
      if (
        state.data.type === 'DRAG_AND_DROP' &&
        state.data.stacks[0]?.items[0]?.given.type === ContentTypesStructureEnums.IMAGE
      ) {
        if (state.data.selectedPage + 1 < state.data.stacks[0]?.items.length) {
          state.data.selectedPage += 1
          state.data.checked = false
        } else {
          // We are on the last stack and exercise have been finished
          state.finished = true
        }
      }
      // Currently, we are on the last item of stack
      if (state.currentItem.itemIndex + 1 === state.data.stacks[state.currentItem.stackIndex].items.length) {
        if (
          // We are not on the last stack
          state.currentItem.stackIndex + 1 < state.data.stacks.length &&
          // And there is another stack with items to go next
          state.data.stacks[state.currentItem.stackIndex + 1].items.length > 0
        ) {
          state.currentItem = {
            stackIndex: state.currentItem.stackIndex + 1,
            itemIndex: 0,
            pageNumber: state.currentItem.pageNumber + 1
          }
        } else {
          // We are on the last stack and exercise have been finished
          state.finished = true
        }
      } else {
        state.currentItem = {
          stackIndex: state.currentItem.stackIndex,
          itemIndex: state.currentItem.itemIndex + 1,
          pageNumber: state.currentItem.pageNumber + 1
        }
      }
    },
    toggleLockPaginable: (state, action) => {
      state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].unlocked =
        !state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].unlocked
    },
    onChangeInputTTA: (state, action) => {
      if (state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].inputType === INLINE) {
        state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].userAnswer.userInputText[
          action.payload.choicesIndex
        ].userAnswer = action.payload.value
      } else {
        state.data.stacks[action.payload.stackIndex].items[
          action.payload.itemIndex
        ].userAnswer.userInputText.userAnswer = action.payload.value
      }
    },
    resetItemSTF: (state, action) => {
      state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].checked = false
    },
    // DADC => (D)drag (A)and (D)drop (C)conversation
    onClickAnswerBoxDADC: (state, action) => {
      let usedChoiceIndex
      state.data.stack.items = state.data.stack.items.map(item => {
        return {
          ...item,
          answerFields: [...item.answerFields].map(field => {
            if (field.index === action.payload.fieldIndex && item.id === action.payload.itemId) {
              if (field.userAnswer) {
                usedChoiceIndex = field.userAnswer.choiceIndex
              }
              return {
                ...field,
                userAnswer: null,
                selected: field.userAnswer ? false : !field.selected
              }
            }
            return {
              ...field,
              selected: false
            }
          })
        }
      })
      if (typeof usedChoiceIndex === 'number') {
        state.data.stack.choices = [...state.data.stack.choices].map(choice => {
          if (choice.choiceIndex === usedChoiceIndex) {
            return {
              ...choice,
              used: false
            }
          }
          return choice
        })
      }

      state.exerciseItemUpdated = true
    },
    // DADL => (D)drag (A)and (D)drop Category / (L)list
    onClickAnswerBoxDADL: (state, action) => {
      let usedChoiceIndex
      state.data.stack.answerFields = [...state.data.stack.answerFields].map(field => {
        if (field.index === action.payload.fieldIndex) {
          if (field.userAnswer) {
            usedChoiceIndex = field.userAnswer.choiceIndex
          }
          return {
            ...field,
            userAnswer: null,
            selected: field.userAnswer ? false : !field.selected
          }
        } else {
          return {
            ...field,
            selected: false
          }
        }
      })
      if (typeof usedChoiceIndex === 'number') {
        state.data.stack.choices = [...state.data.stack.choices].map(choice => {
          if (choice.choiceIndex === usedChoiceIndex) {
            return {
              ...choice,
              used: false
            }
          }
          return choice
        })
      }
      state.data.checkable = state.data.stack.choices.filter(it => !it.isSolvedBefore).every(el => el.used)
    },
    // DAD => (D)drag (A)and (D)drop
    onClickAnswerBoxDAD: (state, action) => {
      let usedChoiceIndex
      state.data.stack.items = state.data.stack.items.map(item => {
        if (item.id === action.payload.itemId) {
          return {
            ...item,
            answerFields: [...item.answerFields].map(field => {
              if (field.index === action.payload.fieldIndex) {
                if (field.userAnswer) {
                  usedChoiceIndex = field.userAnswer.choiceIndex
                }
                return {
                  ...field,
                  userAnswer: null,
                  selected: field.userAnswer ? false : !field.selected
                }
              } else {
                return {
                  ...field,
                  selected: false
                }
              }
            })
          }
        }
        return item
      })
      if (typeof usedChoiceIndex === 'number') {
        state.data.stack.choices = [...state.data.stack.choices].map(choice => {
          if (choice.choiceIndex === usedChoiceIndex) {
            return {
              ...choice,
              used: false
            }
          }
          return choice
        })
      }
      state.data.stack.items[state.data.currentItemIndex].checkable = state.data.stack.items[
        state.data.currentItemIndex
      ].answerFields.every(el => Boolean(el.userAnswer))

      state.exerciseItemUpdated = true
    },
    selectChoiceDNDC: (state, action) => {
      if (action.payload.field.userAnswer) {
        if (action.payload.choice.used) {
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (action.payload.field.userAnswer.choiceIndex === choice.choiceIndex) {
                return {
                  ...choice,
                  used: false
                }
              }
              return choice
            }),
            items: [...state.data.stack.items].map(item => {
              return {
                ...item,
                answerFields: [...item.answerFields].map(field => {
                  if (field.index === action.payload.field.index && item.id === action.payload.field.itemId) {
                    return {
                      ...field,
                      userAnswer: null,
                      selected: false
                    }
                  }
                  return field
                })
              }
            })
          }
        } else {
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (
                action.payload.field.userAnswer &&
                action.payload.field.userAnswer.choiceIndex === choice.choiceIndex
              ) {
                return {
                  ...choice,
                  used: false
                }
              }
              if (action.payload.choice.choiceIndex === choice.choiceIndex) {
                return {
                  ...choice,
                  used: true
                }
              }
              return choice
            }),
            items: [...state.data.stack.items].map(item => {
              return {
                ...item,
                answerFields: [...item.answerFields].map(field => {
                  if (field.index === action.payload.field.index && item.id === action.payload.field.itemId) {
                    return {
                      ...field,
                      userAnswer: action.payload.choice,
                      selected: false
                    }
                  }
                  if (
                    !item.checked &&
                    field.userAnswer &&
                    field.userAnswer.choiceIndex === action.payload.choice.choiceIndex
                  ) {
                    return {
                      ...field,
                      userAnswer: null
                    }
                  }
                  return field
                })
              }
            })
          }
        }
      } else {
        state.data.stack = {
          ...state.data.stack,
          choices: [...state.data.stack.choices].map(choice => {
            if (action.payload.choice.choiceIndex === choice.choiceIndex) {
              return {
                ...choice,
                used: true
              }
            }
            return choice
          }),
          items: [...state.data.stack.items].map(item => {
            return {
              ...item,
              answerFields: [...item.answerFields].map(field => {
                if (field.index === action.payload.field.index && item.id === action.payload.field.itemId) {
                  return {
                    ...field,
                    userAnswer: action.payload.choice,
                    selected: false
                  }
                }
                if (
                  !item.checked &&
                  field.userAnswer &&
                  field.userAnswer.choiceIndex === action.payload.choice.choiceIndex
                ) {
                  return {
                    ...field,
                    userAnswer: null
                  }
                }
                return field
              })
            }
          })
        }
      }
      state.data.checkable = state.data.stack.choices.every(el => el.used)

      // set checkable in items to true if fields are filled
      state.data.stack.items = [...state.data.stack.items].map(item => {
        if (item.id === action.payload.field.itemId) {
          return {
            ...item,
            checkable: item.answerFields.every(el => Boolean(el.userAnswer))
          }
        }
        return item
      })

      state.exerciseItemUpdated = true
    },
    selectChoiceDNDL: (state, action) => {
      if (action.payload.field.userAnswer) {
        if (action.payload.choice.used) {
          // Drop item back into default place
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (action.payload.field.userAnswer.choiceIndex === choice.choiceIndex) {
                return {
                  ...choice,
                  used: false
                }
              }
              return choice
            }),
            answerFields: [...state.data.stack.answerFields].map(field => {
              if (field.index === action.payload.field.index && field.itemId === action.payload.field.itemId) {
                return {
                  ...field,
                  userAnswer: null,
                  selected: false
                }
              }
              return field
            })
          }
        } else {
          // Drop item into filled field
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (
                action.payload.field.userAnswer &&
                action.payload.field.userAnswer.choiceIndex === choice.choiceIndex
              ) {
                return {
                  ...choice,
                  used: false
                }
              }
              if (action.payload.choice.choiceIndex === choice.choiceIndex) {
                return {
                  ...choice,
                  used: true
                }
              }
              return choice
            }),
            answerFields: [...state.data.stack.answerFields].map(field => {
              if (field.index === action.payload.field.index && field.itemId === action.payload.field.itemId) {
                return {
                  ...field,
                  userAnswer: action.payload.choice,
                  selected: false
                }
              }
              if (field.userAnswer && field.userAnswer.choiceIndex === action.payload.choice.choiceIndex) {
                return {
                  ...field,
                  userAnswer: null
                }
              }
              return field
            })
          }
        }
      } else {
        // Drop item into blank field
        state.data.stack = {
          ...state.data.stack,
          choices: [...state.data.stack.choices].map(choice => {
            if (action.payload.choice.choiceIndex === choice.choiceIndex) {
              return {
                ...choice,
                used: true
              }
            }
            return choice
          }),
          answerFields: [...state.data.stack.answerFields].map(field => {
            if (field.index === action.payload.field.index && field.itemId === action.payload.field.itemId) {
              return {
                ...field,
                userAnswer: action.payload.choice,
                selected: false
              }
            }
            if (field.userAnswer && field.userAnswer.choiceIndex === action.payload.choice.choiceIndex) {
              return {
                ...field,
                userAnswer: null
              }
            }
            return field
          })
        }
      }
      state.data.checkable = state.data.stack.choices.filter(it => !it.isSolvedBefore).every(el => el.used)
    },
    selectChoiceDND: (state, action) => {
      if (action.payload.field.userAnswer) {
        if (action.payload.choice.used) {
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (action.payload.field.userAnswer.choiceIndex === choice.choiceIndex) {
                return {
                  ...choice,
                  used: false
                }
              }
              return choice
            }),
            items: [...state.data.stack.items].map(item => {
              if (item.id === action.payload.field.itemId) {
                return {
                  ...item,
                  answerFields: [...item.answerFields].map(field => {
                    if (field.index === action.payload.field.index) {
                      return {
                        ...field,
                        userAnswer: null,
                        selected: false
                      }
                    }
                    return field
                  })
                }
              }
              return item
            })
          }
        } else {
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (
                action.payload.field.userAnswer &&
                action.payload.field.userAnswer.choiceIndex === choice.choiceIndex
              ) {
                return {
                  ...choice,
                  used: false
                }
              }
              if (action.payload.choice.choiceIndex === choice.choiceIndex) {
                return {
                  ...choice,
                  used: true
                }
              }
              return choice
            }),
            items: [...state.data.stack.items].map(item => {
              if (item.id === action.payload.field.itemId) {
                return {
                  ...item,
                  answerFields: [...item.answerFields].map(field => {
                    if (field.index === action.payload.field.index && item.id === action.payload.field.itemId) {
                      return {
                        ...field,
                        userAnswer: action.payload.choice,
                        selected: false
                      }
                    }
                    if (field.userAnswer && field.userAnswer.choiceIndex === action.payload.choice.choiceIndex) {
                      return {
                        ...field,
                        userAnswer: null
                      }
                    }
                    return field
                  })
                }
              }
              return item
            })
          }
        }
      } else {
        state.data.stack = {
          ...state.data.stack,
          choices: [...state.data.stack.choices].map(choice => {
            if (action.payload.choice.choiceIndex === choice.choiceIndex) {
              return {
                ...choice,
                used: true
              }
            }
            return choice
          }),
          items: [...state.data.stack.items].map(item => {
            if (item.id === action.payload.field.itemId) {
              return {
                ...item,
                answerFields: [...item.answerFields].map(field => {
                  if (field.index === action.payload.field.index) {
                    return {
                      ...field,
                      userAnswer: action.payload.choice,
                      selected: false
                    }
                  }
                  if (field.userAnswer && field.userAnswer.choiceIndex === action.payload.choice.choiceIndex) {
                    return {
                      ...field,
                      userAnswer: null
                    }
                  }
                  return field
                })
              }
            }
            return item
          })
        }
      }
      state.data.stack.items[state.data.currentItemIndex].checkable = state.data.stack.items[
        state.data.currentItemIndex
      ].answerFields
        .filter(it => !it.isSolvedBefore)
        .every(el => Boolean(el.userAnswer))
      state.exerciseItemUpdated = true
    },
    toggleLockDADC: (state, action) => {
      state.data.stack.items[action.payload.itemIndex].unlocked =
        !state.data.stack.items[action.payload.itemIndex].unlocked
    },
    onClickContinueDAD: (state, action) => {
      let incorrectChoices = []
      state.data.stack.items.forEach(item => {
        item.answerFields.forEach(field => {
          if (
            field.userAnswerStatus === AnswerStatusEnums.INCORRECT ||
            field.userAnswerStatus === AnswerStatusEnums.SKIPPED
          ) {
            incorrectChoices.push(field.isSolvedBefore ? field.correctAnswer.choiceIndex : field.userAnswer.choiceIndex)
          }
        })
      })
      state.data.stack.choices = [...state.data.stack.choices].map(choice => {
        if (incorrectChoices.includes(choice.choiceIndex)) {
          return {
            ...choice,
            used: false
          }
        } else {
          return choice
        }
      })
      if (action.payload.isConversation) {
        // For drag and drop conversation
        const allItemsAreChecked = state.data.stack.items.filter(it => it.hasAnswer).every(it => it.checked)
        if (allItemsAreChecked) state.finished = true
      } else {
        // For drag and drop
        if (state.data.stack.items.length > state.data.currentItemIndex + 1) {
          state.data.currentItemIndex++
        } else {
          state.finished = true
        }
      }
    },
    setExerciseItemUpdated: (state, action) => {
      state.exerciseItemUpdated = action.payload
    },
    setSayTheFollowingSpeechType: (state, action) => {
      state.data.stacks[action.payload.stackIndex].items[action.payload.itemIndex].speechType =
        action.payload.speechType
    },
    finishExercise: state => {
      state.finished = true
    },
    resetStates: state => {
      state.loading = false
      state.pageType = null
      state.exerciseItemUpdated = false
      state.checkingItem = false
      state.data = {}
      state.isSingleTemplate = false
      state.currentItem = {
        stackIndex: 0,
        itemIndex: 0,
        pageNumber: 1
      }
      state.finished = false
      state.continuable = false
      state.spellPending = false
      state.error = ''
      state.lastDataDetail = {
        unitNumber: null,
        sectionNumber: null,
        groupType: null
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchSectionExercise.pending, state => {
      state.error = null
      state.fetchSectionExerciseLoading = true
    })
    builder.addCase(fetchSectionExercise.fulfilled, (state, action) => {
      state.fetchSectionExerciseLoading = false
      state.data = action.payload.data
      state.lastDataDetail = {
        unitNumber: action.payload.unitNumber,
        sectionNumber: action.payload.sectionNumber,
        groupType: action.payload.groupType
      }
      state.pageType = action.payload.pageType
    })
    builder.addCase(fetchSectionExercise.rejected, (state, action) => {
      state.fetchSectionExerciseLoading = false
      state.error = action.error.message
    })
    builder.addCase(checkExerciseItem.pending, state => {
      state.checkingItem = true
      state.error = null
    })
    builder.addCase(checkExerciseItem.fulfilled, (state, action) => {
      const isCorrect = action.payload.data?.exerciseCheckItemResponse?.every(it => {
        return it.userAnswerItemResponse?.every(it => it.userAnswerStatus === AnswerStatusEnums.CORRECT)
      })
      const isSkipped = action.payload.data?.exerciseCheckItemResponse?.every(it => {
        return it.userAnswerItemResponse?.every(it => it.userAnswerStatus === AnswerStatusEnums.SKIPPED)
      })
      playCheckSound(isCorrect, isSkipped)

      state.checkingItem = false
      // Combine check response with the data
      state.data = getDataMergedWithCheckResponse(action.payload.stackId, action.payload.data, state.data)
    })
    builder.addCase(checkExerciseItem.rejected, (state, action) => {
      state.checkingItem = false
      state.error = action.payload.error

      const exerciseItemUserData = action?.meta?.arg?.userAnswerData?.userAnswerItems?.[0]
      const exerciseItemId = exerciseItemUserData?.exerciseItemId
      const exerciseItemUserAnswers = exerciseItemUserData?.userAnswers

      switch (state.data.type) {
        case exerciseTypeEnums.putInOrder: {
          const currentItem = state.data?.stacks?.[state.currentItem?.stackIndex]?.items?.[state.currentItem?.itemIndex]
          if (currentItem) {
            state.data.stacks[state.currentItem.stackIndex].items[state.currentItem.itemIndex] = {
              ...currentItem,
              unlocked: false,
              checked: false,
              userAnswer: null,
              choices: [...currentItem.choices].map(choice => {
                return {
                  ...choice,
                  selected: false
                }
              }),
              userChoices: []
            }
          }
          break
        }
        case exerciseTypeEnums.dragAndDropConversation: {
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (
                exerciseItemUserAnswers?.some(
                  it => it.text === choice?.text?.sentence || it.trackerNumber === choice?.text?.trackerNumber
                )
              ) {
                return {
                  ...choice,
                  used: false
                }
              }
              return choice
            }),
            items: [...state.data.stack.items].map(item => {
              if (item.id === exerciseItemId) {
                return {
                  ...item,
                  checked: false,
                  checkable: false,
                  answerFields: [...item.answerFields].map(answerField => {
                    return {
                      ...answerField,
                      userAnswer: null,
                      userAnswerStatus: null
                    }
                  })
                }
              }
              return item
            })
          }
          break
        }
        case exerciseTypeEnums.dragAndDrop: {
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              if (
                exerciseItemUserAnswers?.some(
                  it => it.text === choice?.text?.sentence || it.trackerNumber === choice?.text?.trackerNumber
                )
              ) {
                return {
                  ...choice,
                  used: false
                }
              }
              return choice
            }),
            items: [...state.data.stack.items].map(item => {
              if (item.id === exerciseItemId) {
                return {
                  ...item,
                  checked: item.isSolvedBefore,
                  checkable: false,
                  answerFields: [...item.answerFields].map(field => {
                    return {
                      ...field,
                      selected: false,
                      userAnswer: field.isSolvedBefore ? field.userAnswer : null,
                      userAnswerStatus: field.isSolvedBefore ? field.userAnswerStatus : null
                    }
                  })
                }
              }
              return item
            })
          }
          break
        }
        case exerciseTypeEnums.dragAndDropCategory:
          state.data.stack = {
            ...state.data.stack,
            choices: [...state.data.stack.choices].map(choice => {
              return {
                ...choice,
                used: false
              }
            }),
            answerFields: [...state.data.stack.answerFields].map(field => {
              return {
                ...field,
                selected: false,
                correctAnswer: null,
                userAnswer: null,
                userAnswerStatus: null
              }
            })
          }
          break
        default:
          break
      }
    })
    builder.addCase(speechToText.pending, state => {
      state.spellPending = true
    })
    builder.addCase(speechToText.fulfilled, (state, action) => {
      state.spellPending = false

      state.userData = [...state.userData].map((obj, i) => {
        if (i === state.currentIndex) {
          return {
            ...obj,
            userAnswer: action.payload.data.voiceStr
          }
        } else {
          return obj
        }
      })
      state.recordModalIsOpen = false
    })
  }
})

export const {
  setSectionExercise,
  onClickOptionCFC,
  matchOptions,
  unmatchOptions,
  toggleLockMatch,
  toggleViewType,
  resetAll,
  selectOptionPIOC,
  unselectOptionPIOC,
  toggleLockPutInOrderConversation,
  selectOptionPIO,
  unselectOptionPIO,
  toggleLockCFC,
  selectOptionMC,
  dragDropChangeChoose,
  dragDropChangeAnswer,
  dragDropNextPage,
  selectChoiceCombo,
  changeGroupType,
  goToNextItem,
  toggleLockPaginable,
  onChangeInputTTA,
  resetItemSTF,
  finishExercise,
  resetStates,
  onClickAnswerBoxDADC,
  selectChoiceDNDC,
  toggleLockDADC,
  onClickAnswerBoxDADL,
  selectChoiceDNDL,
  onClickAnswerBoxDAD,
  selectChoiceDND,
  onClickContinueDAD,
  setExerciseItemUpdated,
  setSayTheFollowingSpeechType
} = exerciseSlice.actions

export default exerciseSlice.reducer
