import { deleteNotificationsByClassService, deleteNotificationsByIdService, getNotificationInboxByClassService, getNotificationInboxCountService, getNotificationInboxService, seenNotificationByIdService } from "services/api/notification"
import { NotificationClassType } from "services/api/notification/types"
import notificationsInboxCountMapper from "services/mapper/messages/notificationInboxCountMapper"
import notificationInboxMapper from "services/mapper/messages/notificationInboxMapper"

export const getNotificationInboxCountUseCase = async () => {
  const response = await getNotificationInboxCountService()

  return notificationsInboxCountMapper(response)
}

export const getNotificationInboxUseCase = async ({ classType }: { classType: NotificationClassType }) => {
  const response = classType === NotificationClassType.all ? await getNotificationInboxService() : await getNotificationInboxByClassService(classType)

  return notificationInboxMapper(response)
}

export const seenNotificationByIdUseCase = async ({ id }: { id: string }) => {
  const response = await seenNotificationByIdService(id)
  return response
}

export const deleteNotificationsByClassUseCase = async ({ classTypes }: { classTypes: NotificationClassType[] }) => {
  const response = await deleteNotificationsByClassService(classTypes)
  return response
}

export const deleteNotificationsByIdUseCase = async ({ ids }: { ids: string[] }) => {
  const response = await deleteNotificationsByIdService(ids)
  return response
}