import userAxiosInstance from "apis/userAxiosInstance"
import type { NotificationClassType, NotificationInboxCountServiceModel, NotificationInboxServiceModel } from "./types"

export const getNotificationInboxCountService = async (): Promise<NotificationInboxCountServiceModel> => {
  return await userAxiosInstance.get('/v1/notification/inbox/count').then(response => {
    return response.data
  })
}

export const getNotificationInboxService = async (): Promise<NotificationInboxServiceModel[]> => {
  return await userAxiosInstance.get('/v1/notification/inbox').then(response => {
    return response.data
  })
}

export const getNotificationInboxByClassService = async (classType: NotificationClassType): Promise<NotificationInboxServiceModel[]> => {
  return await userAxiosInstance.get(`/v1/notification/inbox/${classType}`).then(response => {
    return response.data
  })
}

export const deleteNotificationByClassService = async (classType: NotificationClassType) => {
  return await userAxiosInstance.delete('/v1/notification/inbox/action/delete-by-class', {
    params: {
      notificationClass: classType
    }
  }).then(response => {
    return response.data
  })
}

export const seenNotificationByIdService = async (id: string) => {
  return await userAxiosInstance.put(
    '/v1/notification/inbox/action/update',
    null,
    {
      params: {
        notificationId: id
      }
    }
  ).then(response => {
    return response.data
  })
}

export const deleteNotificationsByClassService = async (classTypes: NotificationClassType[]) => {
  return await userAxiosInstance.delete('/v1/notification/inbox/action/delete-by-class', {
    params: {
      notificationClass: classTypes
    },
    paramsSerializer: { indexes: null }
  }).then(response => {
    return response.data
  })
}

export const deleteNotificationsByIdService = async (ids: string[]) => {
  return await userAxiosInstance.delete('/v1/notification/inbox/action/delete-by-ids', {
    params: {
      ids
    },
    paramsSerializer: { indexes: null }
  }).then(response => {
    return response.data
  })
}