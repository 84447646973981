import { studyTypeEnum } from '../../../enums/userStudyEnums/userStudyEnums'
import studyTypeIsRepeat from '../../../operations/studyTypeIsRepeat'
import { TYPE_A } from '../../../enums/exerciseEnums/exerciseGroupTypeEnums'
import sectionsMapper from '../levelAndUnit/sectionsMapper'

export const getStudyTypeBadge = studyStatus => {
  if (studyStatus === studyTypeEnum.NEW) {
    return 'new'
  } else if (studyTypeIsRepeat(studyStatus)) {
    return 'repeat'
  } else if (studyStatus === studyTypeEnum.REVIEW) {
    return 'review'
  } else {
    return 'new'
  }
}

const todayStudyMapper = (data = null) => {
  const getPlanObject = plan => {
    return {
      id: `${plan.unitId}-${plan.studyStatus}`,
      levelNumber: plan.levelPlace ?? 1,
      levelName: plan.levelStr,
      levelKey: plan.level,
      day: plan.day,
      unitId: plan.unitId,
      unitTitle: plan.unitTitle,
      unitDescription: plan.unitDescription,
      repeatNo: plan.repeatNo,
      idealLocation: plan.idealLocation,
      relocate: plan.relocate,
      lastRepeatDate: plan.lastRepeatDate,
      distance: plan.distance,
      studyStatus: plan.studyStatus,
      group: plan.group ?? TYPE_A,
      dueTime: plan.dueTime ?? 0,
      doneSections: plan.doneSections,
      doneSectionsPercentage: Math.floor(plan.doneSectionsPercentage),
      sectionList: sectionsMapper(plan.sectionList),
      isFinished: plan.isFinished
    }
  }

  return {
    currentDay: {
      studyType: getStudyTypeBadge(data?.currentDay?.[0]?.studyStatus),
      plans: data?.currentDay?.map(item => getPlanObject(item))
    },
    nextDay: {
      studyType: getStudyTypeBadge(data?.nextDay?.[0]?.studyStatus),
      plans: data?.nextDay?.map(item => getPlanObject(item))
    }
  }
}

export default todayStudyMapper
